import React, { Component } from 'react';

import NavbarSistema from './navbarSistema';
import RotasSistema from './rotasSistema';
import Footer from '../footer/index';

export default class Home extends Component {
    render() {
        return (
            <div className="cordeFundo">
                <NavbarSistema />
                <RotasSistema />
                <Footer />
            </div>
        );
    }
}