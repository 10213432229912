import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { FaPlus } from "react-icons/fa";

export default class BotaoForm extends Component {
    render() {
        return (
            <Link to={{
                pathname: '/Home/Colaborador/NovoColaborador/Formulario',
                state: {
                    IDColaborador: '',
                    nome: '',
                    PIS: '',
                    UserName: '',
                    alterasenha: true, //Alterei Jorge
                    localizacao: false,
                    foto: false,
                    CPF: '', //Adicionei Jorge
                    serieCarteira: '', //Adicionei Jorge
                    numeroCarteira: '', //Adicionei Jorge
                    cargo: '', //Adicionei Jorge
                    matricula: '' //Adicionei Jorge
                }
            }}>
                <button className="btn btn-outline-primary">
                    <FaPlus size={20} className="icone" />
                </button>
            </Link>
        );
    }
}