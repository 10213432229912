import React from "react";
import { Switch, Route } from 'react-router-dom';

import TabelaPermissao from './tabela_Permissoes';
import FormularioPermissao from './form_permissoes';

const RotasPermissao = () => (
    <Switch>
        <Route path='/Home/Permissoes/Tabela' component={TabelaPermissao} />
        <Route path='/Home/Permissoes/NovaPermissao' component={FormularioPermissao} />
    </Switch>
);

export default RotasPermissao;