export const changeIDEmpresa = (empresaSelecionada) => {
    return {
        type: 'changeIDEmpresa',
        payload: { empresaSelecionada }
    };
}

export const changeEmpresas = (options) => {
    return {
        type: 'changeEmpresas',
        payload: { options: options }
    };
}

export const changeReset = () => {
    return {
        type: 'changeReset',
        payload: { options: [], empresaSelecionada: '' }
    };
}

export const changeRole = (role) => {
    return {
        type: 'changeRole',
        payload: { role }
    };
}

export const changeConectado = (conectado) => {
    return {
        type: 'changeConectado',
        payload: { conectado }
    };
}

export const changeLogout = () => {
    return {
        type: 'changeLogout',
        payload: { conectado: false, id: '', username: '' }
    }
}

export const changeID = (id) => {
    return {
        type: 'changeID',
        payload: { id }
    }
}

export const changeUsername = (username) => {
    return {
        type: 'changeUsername',
        payload: { username }
    }
}
