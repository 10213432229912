import React, { Component } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import Modal from 'react-modal';
import { Redirect } from 'react-router-dom'
import { Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import CheckBox from 'react-simple-checkbox';
import {
    changeRole, changeConectado,
    changeID,
    changeUsername
} from '../Home/actions/actionsIDEmpresa';
import logo from '../../img/logoSemFundo.png';

Modal.setAppElement('#root');

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            username: '',
            senha: '',
            erro: '',
            redirect: false,
            tipoErro: [],
            modalopen: false,
            loading: false,
            conectado: false
        }


        this.atribuiid = this.atribuiid.bind(this);
        this.atribuiusername = this.atribuiusername.bind(this);
        this.atribuisenha = this.atribuisenha.bind(this);

        this.entrar = this.entrar.bind(this);

        this.modalErro = this.modalErro.bind(this);
        this.modalLoading = this.modalLoading.bind(this);

        this.renderRedirect = this.renderRedirect.bind(this);
        this.atribuiConectado = this.atribuiConectado.bind(this);
    }

    renderRedirect() {
        let redirect = this.state.redirect;
        if (redirect === true) {
            return <Redirect to={{
                pathname: '/Home/Empresa/Tabela'
            }} />
        }
    }

    atribuiid(event) {
        let id = event.target.value;

        this.setState({ id })
        this.props.changeID(id);
    }

    atribuiusername(event) {
        let username = event.target.value;

        this.setState({ username })
        this.props.changeUsername(username);
    }

    atribuisenha(event) {
        let senha = event.target.value;

        this.setState({ senha })
    }

    modalErro() {
        this.setState({ modalopen: !this.state.modalopen })
    }

    modalLoading() {
        this.setState({ loading: !this.state.loading })
    }

    entrar(event) {
        this.setState({ loading: !this.state.loading });
        event.preventDefault();

        axios.defaults.withCredentials = true;

        axios.post(process.env.REACT_APP_URL_LOGIN,
            // axios.post(process.env.REACT_APP_URL_LOGIN,
            {
                IDFront: this.props.id,
                UserName: this.props.username,
                Senha: this.state.senha
            },
            {

                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                if (res.status === 200) {
                    const cookies = new Cookies();

                    cookies.set('token', res.data.token, { path: '/' });
                    cookies.set('refreshToken', res.data.refreshToken, { path: '/' });
                    this.props.changeRole(res.data.role);

                    this.setState({
                        redirect: true,
                        loading: !this.state.loading
                    })
                }
                else {
                    let erro = this.state.erro;
                    erro = 'Login Inválido';
                    this.setState({ erro });

                    let cookies = this.state.cookies;
                    cookies.remove('token');
                    cookies.remove('refreshToken');
                }
            })
            .catch((error) => {
                if (error.response !== undefined) {
                    this.setState({
                        tipoErro: error.response.data === '' ? "Credenciais inválidas" : error.response.data,
                        loading: !this.state.loading
                    });
                    this.modalErro();
                }
                else {
                    this.setState({
                        tipoErro: "Não foi possível conectar com o servidor",
                        loading: !this.state.loading
                    });
                    this.modalErro();
                }
            });
    }

    atribuiConectado(conectado) {
        this.setState({ conectado });
        this.props.changeConectado(conectado);
    }

    render() {
        return (
            <div className="row justify-content-center h-100 fundoLogin">
                <Modal  //Modal de erro
                    isOpen={this.state.modalopen}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center eventodiv">
                        <label> Ops! Temos problemas: </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p>{this.state.tipoErro}</p>}
                        <div className="row">
                            <div className="col">
                                <button onClick={this.modalErro} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal  //Modal de Loading
                    isOpen={this.state.loading}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center eventodiv">
                        <label> Conectando... </label><br />
                        <Spinner color="primary" size="lg" />
                    </div>
                </Modal>
                <div className="col-12 col-sm-11 col-md-8 col-lg-6 col-xl-4 my-auto">
                    <div className="card cardLogin">
                        <div className="logo text-center">
                            <img src={logo} alt="LOGO" className="col-6 col-x-4" />
                        </div>

                        <div className="card-body">
                            <form onSubmit={this.entrar}>
                                <div className="form-row">
                                    <div className="form-group col-12 col-sm-5 col-md-4 col-lg-4 col-xl-4">
                                        <label htmlFor="id" className="label">ID:</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="id"
                                            name="id"
                                            value={this.props.id}
                                            onChange={this.atribuiid}
                                            maxLength="5"
                                            required="required"
                                            autoComplete="off"
                                        />
                                    </div>

                                    <div className="form-group col-12 col-sm-7 col-md-8 col-lg-8 col-xl-8">
                                        <label htmlFor="username" className="label">Username:</label>
                                        <input
                                            type="text"
                                            id="username"
                                            name="username"
                                            onChange={this.atribuiusername}
                                            value={this.props.username}
                                            className='form-control  '
                                            maxLength="40"
                                            required="required"
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="senha" className="label">Senha:</label>
                                    <input
                                        className="form-control"
                                        type="password"
                                        id="senha"
                                        name="senha"
                                        onChange={this.atribuisenha}
                                        value={this.state.senha}
                                        maxLength="30"
                                        required="required"
                                        autoComplete="off"
                                    />
                                </div>

                                <div className="form-row margemBottom">
                                    <div className="form-group">
                                        <div className="col">
                                            <CheckBox
                                                id='conectado'
                                                size={4}
                                                tickSize={3}
                                                borderThickness={1}
                                                checked={this.props.conectado}
                                                onChange={this.atribuiConectado}
                                                color={{ backgroundColor: '#007bef', borderColor: '#007bef', uncheckedBorderColor: '#ced4da' }}
                                                tickAnimationDuration={100}
                                                backAnimationDuration={100} />
                                            <label htmlFor='conectado' className="alinhamentoCheck align-middle">Mantenha-me conectado</label>
                                        </div>
                                    </div>
                                </div>

                                <button type="submit" className="btn btn-primary btn-lg btn-block">
                                    Entrar
                                    </button>
                            </form>
                        </div>
                    </div>
                </div>
                {this.renderRedirect()}
            </div>
        );
    }
}

const mapStateToProp = (state) => ({
    role: state.dadosEmpresa.role,
    conectado: state.dadosEmpresa.conectado,
    id: state.dadosEmpresa.id,
    username: state.dadosEmpresa.username
});

const mapActionstoProps = {
    changeRole,
    changeConectado,
    changeID,
    changeUsername
}

export default connect(mapStateToProp, mapActionstoProps)(Login);