import React, { Component } from "react";
import { Link, Redirect } from 'react-router-dom'
import Modal from 'react-modal';
import axios from 'axios';
import Cookies from 'universal-cookie';
import Select from 'react-select';

Modal.setAppElement('#root');

const Requisicoes = Object.freeze({
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
});

const options = [
    { value: '0', label: 'Inclusão' },
    { value: '1', label: 'Alteração' },
    { value: '2', label: 'Exclusão' },
];

export default class FormularioUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            IDPermissao: '',
            permissaoColaborador: [],
            permissaoEmpresa: [],
            permissaoUsuario: [],
            descricao: '',

            sucesso: false,
            erro: false,
            cancela: false,
            refresh: false,

            mensagemSucesso: '',
            tipoErro: [],
            redirect: false,

            token: '',
            refreshToken: '',
        }

        this.atribui_permissaoColaborador = this.atribui_permissaoColaborador.bind(this);
        this.atribui_permissaoEmpresa = this.atribui_permissaoEmpresa.bind(this);
        this.atribui_permissaoUsuario = this.atribui_permissaoUsuario.bind(this);
        this.atribuiDescricao = this.atribuiDescricao.bind(this);

        this.enviaDados = this.enviaDados.bind(this);

        this.ModalSucesso = this.ModalSucesso.bind(this);
        this.ModalErro = this.ModalErro.bind(this);
        this.ModalCancelamento = this.ModalCancelamento.bind(this);
        this.ModalRefresh = this.ModalRefresh.bind(this);

        this.OnClickEnvia = this.OnClickEnvia.bind(this);
        this.requisicaoRefresh = this.requisicaoRefresh.bind(this);
    }

    componentDidMount() {
        // let cookie = new Cookies();
        // let token = cookie.get('token');
        // let permissoes = [];

        // axios.get('https://192.168.1.246:5000/permissoes',
        //     {
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Authorization': 'Bearer ' + token
        //         }
        //     })
        //     .then(res => {
        //         permissoes = res.data;
        //         this.setState({ permissoes });
        //     })
    }

    atribui_permissaoColaborador(permissaoColaborador) {
        this.setState({ permissaoColaborador })
    }

    atribui_permissaoEmpresa(permissaoEmpresa) {
        this.setState({ permissaoEmpresa })
    }

    atribui_permissaoUsuario(permissaoUsuario) {
        this.setState({ permissaoUsuario });
    }

    atribuiDescricao(event) {
        this.setState({ descricao: event.target.value })
    }

    OnClickEnvia(event) {
        event.preventDefault();

        this.enviaDados();
    }

    enviaDados(event) {
        event.preventDefault();

        if (this.state.IDPermissao === '') {
            this.requisicaoEnvio();
        }
        else {
            this.requisicaoAlteracao();
        }
    }

    requisicaoAlteracao() {
        let cookie = new Cookies();
        let token = cookie.get('token');

        axios.put(process.env.REACT_APP_URL_REQUISICOES + 'permissoes/' + this.state.IDPermissao,
            {
                IDPermissao: this.state.IDPermissao,
            },
            {
                 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                if (res.status === 201) {
                    this.ModalSucesso();

                    this.setState({
                        mensagemSucesso: 'Alteração feita com sucesso!'
                    })
                }
            })
            .catch((error) => {
                if (error.response !== undefined) {
                    if (error.response.status === 401) {
                        let tipoRequisicao = Requisicoes.POST

                        this.requisicaoRefresh(tipoRequisicao);

                    }
                    else if (error.response.status === 403) {
                        this.setState({
                            tipoErro: "Ação não autorizada"
                        });
                        this.ModalErro();
                    }
                    else {
                        this.setState({
                            tipoErro: error.response.data
                        });
                        this.ModalErro();
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro desconhcido. Contate um administrador"
                    });
                    this.ModalRefresh();
                }
            });
    }

    requisicaoEnvio() {
        let cookie = new Cookies();
        let token = cookie.get('token');

        // axios.post(process.env.REACT_APP_URL_BASENAME + 'permissoes',
        axios.post(process.env.REACT_APP_URL_REQUISICOES + 'permissoes',
            {
                Descricao: this.state.descricao,
                Codigo: this.state.IDPermissao,
                // InsereEmpresa: this.state.permissaoColaborador.InsereColaborador
                // AlteraEmpresa
                // ExcluiEmpresa
                // InsereColaborador
                // AlteraColaborador
                // ExcluiColaborador
                // InsereUsuario
                // AlteraUsuario
                // ExcluiUsuario
            },
            {
                 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                if (res.status === 201) {
                    this.ModalSucesso();

                    this.setState({
                        mensagemSucesso: 'Cadastro feito com sucesso!'
                    })
                }
            })
            .catch((error) => {
                if (error.response !== undefined) {
                    if (error.response.status === 401) {
                        let tipoRequisicao = Requisicoes.POST

                        this.requisicaoRefresh(tipoRequisicao);

                    }
                    else if (error.response.status === 403) {
                        this.setState({
                            tipoErro: "Ação não autorizada"
                        });
                        this.ModalErro();
                    }
                    else {
                        this.setState({
                            tipoErro: error.response.data
                        });
                        this.ModalErro();
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro desconhcido. Contate um administrador"
                    });
                    this.ModalRefresh();
                }
            });
    }

    requisicaoRefresh(tipoRequisicao) {
        let cookie = new Cookies();
        let token = cookie.get('token')
        let refreshToken = cookie.get('refreshToken')

        axios.post(process.env.REACT_APP_URL_REQUISICOES + 'auth/refresh',
            {
                refreshToken: refreshToken
            },
            {
                 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                if (res.status === 200) {
                    const cookies = new Cookies();

                    cookies.set('token', res.data.token, { path: '/' });
                    cookies.set('refreshToken', res.data.refreshToken, { path: '/' });

                    switch (tipoRequisicao) {
                        case Requisicoes.POST:
                            this.requisicaoEnvio();
                            break;
                        case Requisicoes.PUT:
                            this.requisicaoAlteracao();
                            break;
                        default:
                            break;
                    }
                }
            })
            .catch((error) => {
                let tipoErro;
                if (error !== undefined) {
                    if (error.response.status === 401) {
                        tipoErro = 'Sessão Inválida'
                    }
                    else {
                        tipoErro = error.response.data;
                    }
                }
                else {
                    tipoErro = 'Erro desconhecido. Contate o administrador';
                }

                this.setState({
                    tipoErro: tipoErro
                });

                this.ModalRefresh();
            })
    }

    ModalRefresh() {
        this.setState({ refresh: true })
    }

    ModalSucesso() {
        this.setState({ sucesso: false, redirect: true })
    }

    ModalErro() {
        this.setState({ erro: true })
    }

    ModalCancelamento() {
        this.setState({ cancela: false })
    }

    render() {
        let redirecionamento = this.state.redirect ? <Redirect to="/Home/Permissoes/Tabela" /> : null;
        return (
            <div className="row justify-content-center h-100 margemTop expansaoDiv">
                <Modal //modal de cancelamento
                    isOpen={this.state.cancela}
                    //onRequestClose={this.ModalCancelamento}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label> Suas informações serão perdidas. Deseja continuar? </label>
                        <div className="row">
                            <div className="col text-right">
                                <Link to='/Home/Permissoes/Tabela'><button className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Sim</button></Link>
                            </div>

                            <div className="col text-left">
                                <button onClick={this.ModalCancelamento} className="btn btn-outline-danger btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Não</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de sucesso
                    isOpen={this.state.sucesso}
                    //onRequestClose={this.ModalSucesso}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label>{this.state.mensagemSucesso}</label><br />

                        <div className="row">
                            <div className="col">
                                <Link to="/Home/User/Tabela"><button onClick={this.ModalSucesso} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button></Link>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de erro
                    isOpen={this.state.erro}
                    //onRequestClose={this.ModalErro}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center modalErro">
                        <label> Ops! Temos problemas: </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p> {this.state.tipoErro} </p>}
                        <div className="row">
                            <div className="col">
                                <button onClick={this.ModalErro} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de erro refresh
                    isOpen={this.state.refresh}
                    //onRequestClose={this.ModalRefresh}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center modalErro">
                        <label> Ops! Temos problemas: </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p> {this.state.tipoErro} </p>}
                        <div className="row">
                            <div className="col">
                                <Link to="/"><button onClick={this.ModalErro} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button></Link>
                            </div>
                        </div>
                    </div>
                </Modal>
                <div className="col-12 col-sm-11 col-md-9 col-lg-10 col-xl-8">
                    <div>
                        <div className="h4 text-center">
                            Permissão
                        </div>

                        <form onSubmit={this.enviaDados}>
                            <div className="form-row centralizaPermissoes">
                                <div className="form-group col-12 col-sm-8 col-md-8 col-lg-6 col-xl-6">
                                    <label htmlFor="descricao">Descrição:</label>
                                    <input
                                        className='form-control'
                                        type="text"
                                        id="descricao"
                                        name="descricao"
                                        value={this.state.descricao}
                                        onChange={this.atribuiDescricao}
                                        maxLength="100"
                                        required="required"
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                            <div className="form-row centralizaPermissoes">
                                <div className="form-group col-12 col-sm-8 col-md-8 col-lg-6 col-xl-6">
                                    <label htmlFor="Colaborador">Colaborador:</label>
                                    <Select
                                        value={this.state.permissaoColaborador}
                                        onChange={this.atribui_permissaoColaborador}
                                        options={options}
                                        isMulti
                                        closeMenuOnSelect={false} //mantem a caixa de seleção aberta
                                    />
                                </div>
                            </div>

                            <div className="form-row centralizaPermissoes">
                                <div className="form-group col-12 col-sm-8 col-md-8 col-lg-6 col-xl-6">
                                    <label htmlFor="UserName">Empresa:</label>
                                    <Select
                                        value={this.state.permissaoEmpresa}
                                        onChange={this.atribui_permissaoEmpresa}
                                        options={options}
                                        isMulti
                                        closeMenuOnSelect={false}
                                    />
                                </div>
                            </div>

                            <div className="form-row centralizaPermissoes">
                                <div className="form-group col-12 col-sm-8 col-md-8 col-lg-6 col-xl-6">
                                    <label htmlFor="UserName">Usuário:</label>
                                    <Select
                                        value={this.state.permissaoUsuario}
                                        onChange={this.atribui_permissaoUsuario}
                                        options={options}
                                        isMulti
                                        closeMenuOnSelect={false}
                                    />
                                </div>
                            </div>

                            <div className="form-row centralizaPermissoes">
                                <div className="form-group col text-right">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-sm col-7 col-sm-6 col-md-5 col-lg-4 col-xl-4">
                                        Salvar
                                    </button>
                                </div>
                                <div className="form-group col text-left">
                                    <button
                                        type="button"
                                        onClick={this.ModalCancelamento}
                                        className="btn btn-danger btn-sm col-7 col-sm-6 col-md-5 col-lg-4 col-xl-4">
                                        Cancelar
                                        </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {redirecionamento}
            </div>
        );
    }
}