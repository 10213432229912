import React, { Component } from "react";
import { Link } from 'react-router-dom'
import Modal from 'react-modal';
import axios from 'axios';
import Cookies from 'universal-cookie'
import CheckBox from 'react-simple-checkbox';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';
// import { Redirect } from 'react-router-dom';

Modal.setAppElement('#root');

const Requisicoes = Object.freeze({
    POST: 'POST',
    PUT: 'PUT'
});

class FormularioColaborador extends Component {
    constructor(props) {
        super(props);

        this.state = {
            nome: this.props.location.state.nome,
            nome_erro: '',

            CPF: this.props.location.state.CPF,
            CPF_erro: '',

            PIS: this.props.location.state.PIS,
            PIS_erro: '',

            UserName: this.props.location.state.UserName,
            UserName_erro: '',

            alterasenha: this.props.location.state.alterasenha,

            localizacao: this.props.location.state.localizacao,
            foto: this.props.location.state.foto,
            IDColaborador: this.props.location.state.IDColaborador,

            serieCarteira: this.props.location.state.serieCarteira,
            numeroCarteira: this.props.location.state.numeroCarteira,
            cargo: this.props.location.state.cargo,
            matricula: this.props.location.state.matricula,
            matricula_erro: '',

            sucesso: false,
            erro: false,
            cancela: false,
            refresh: false,
            loading: false,

            validador: false,
            mensagemSucesso: '',
            tipoErro: [],
            mensagemModal: 'Ops! Temos problemas:',
            redirect: false
        }

        this.atribuiNome = this.atribuiNome.bind(this);
        this.validaNome = this.validaNome.bind(this);

        this.atribuiPIS = this.atribuiPIS.bind(this);
        this.validaPIS = this.validaPIS.bind(this);

        this.atribuiUserName = this.atribuiUserName.bind(this);
        this.validaUserName = this.validaUserName.bind(this);

        this.atribuiAlteraSenha = this.atribuiAlteraSenha.bind(this);

        this.atribuiFoto = this.atribuiFoto.bind(this);
        this.atribuiLocalizacao = this.atribuiLocalizacao.bind(this);

        this.ModalSucesso = this.ModalSucesso.bind(this);
        this.ModalErro = this.ModalErro.bind(this);
        this.ModalCancelamento = this.ModalCancelamento.bind(this);
        this.ModalRefresh = this.ModalRefresh.bind(this);

        this.OnClickEnvia = this.OnClickEnvia.bind(this);
        this.requisicaoRefresh = this.requisicaoRefresh.bind(this);
        this.PontuacaoPIS = this.PontuacaoPIS.bind(this);

        this.requisicaoAlteracao = this.requisicaoAlteracao.bind(this);
        this.requisicaoEnvio = this.requisicaoEnvio.bind(this);

        this.atribuiNumeroCarteira = this.atribuiNumeroCarteira.bind(this);
        this.atribuiSerieCarteira = this.atribuiSerieCarteira.bind(this);
        this.atribuiCargo = this.atribuiCargo.bind(this);

        this.atribuiMatricula = this.atribuiMatricula.bind(this);
        this.validaMatricula = this.validaMatricula.bind(this);

        this.atribuiCPF = this.atribuiCPF.bind(this);
        this.validaCPF = this.validaCPF.bind(this);

        this.ModalLoading = this.ModalLoading.bind(this);
    }

    atribuiCPF(event) {
        let CPF = event.target.value.replace(/\D/g,'');

        if(CPF.length === 11){
            CPF = this.mascaraCPF(CPF);
        }

        this.setState({ CPF })

        this.validaCPF(CPF)
    }

    validaCPF(cpfParam) {
        let CPF = this.state.CPF;

        if(cpfParam){
            CPF = cpfParam
        }

        if (CPF !== '') {
            CPF = CPF.replace(/[^\d]+/g, '');
            var validador = true;

            if (CPF === '') {
                validador = false;
            }

            else if (CPF.length !== 11 ||
                CPF === '00000000000' ||
                CPF === '11111111111' ||
                CPF === '22222222222' ||
                CPF === '33333333333' ||
                CPF === '44444444444' ||
                CPF === '55555555555' ||
                CPF === '66666666666' ||
                CPF === '77777777777' ||
                CPF === '88888888888' ||
                CPF === '99999999999') {
                validador = false;
            }

            else {
                var add = 0;
                for (var j = 0; j < 9; j++) {
                    add += parseInt(CPF.charAt(j)) * (10 - j);
                }
                var rev = (add % 11);

                if (rev < 2)
                    rev = 0;
                else
                    rev = 11 - rev;

                if (rev !== parseInt(CPF.charAt(9))) {
                    validador = false;
                }

                add = 0;
                for (var i = 0; i < 10; i++) {
                    add += parseInt(CPF.charAt(i)) * (11 - i);
                    rev = 11 - (add % 11);
                }

                if (rev === 10 || rev === 11) {
                    rev = 0;
                }

                else if (rev !== parseInt(CPF.charAt(10))) {
                    validador = false;
                }
            }

            let CPF_erro = validador ? '' : 'CPF Inválido'
            validador = validador && true;//&& = and
            this.setState({ CPF_erro })

            // this.mascaraCPF(CPF);
        }
        else {
            this.setState({ CPF_erro: 'O CPF deve ser preenchido' })
        }
    }

    mascaraCPF(CPF) {
        // let { validador } = this.state;

        // if (CPF.length === 11) {
        //     let CPFAux1 = '';
        //     let CPFAux2 = '';
        //     let CPFAux3 = '';
        //     let CPFAux4 = '';

        //     CPFAux1 += CPF.substring(0, 3) + '.';
        //     CPFAux2 += CPF.substring(3, 6) + '.';
        //     CPFAux3 += CPF.substring(6, 9) + '-';
        //     CPFAux4 += CPF.substring(9, 11);

        //     let CPFPontuacao = CPFAux1 + CPFAux2 + CPFAux3 + CPFAux4;

        //     this.setState({ CPF: CPFPontuacao });
        // }
        // else {
        //     let CPF_erro = validador ? null : 'CPF Inválido'
        //     this.setState({ CPF_erro })
        // }

        let cpfPontuacao = CPF.replace(/^([\d]{3})([\d]{3})([\d]{3})([\d]{2})$/, "$1.$2.$3-$4");

        return cpfPontuacao
    }

    atribuiNome(event) {
        this.setState({ nome: event.target.value })

        this.validaNome(event.target.value)
    }

    validaNome(nomeParam){
        let nome = this.state.nome;
        let nome_erro = '';

        if(nomeParam){
            nome = nomeParam
        }

        if(nome.length === 0){
            nome_erro = 'O nome deve ser preenchido'
        }

        this.setState({
            nome_erro
        })
    }

    atribuiPIS(event) {
        let PIS = event.target.value.replace(/\D/g,'');

        if (PIS.length === 11) {
            PIS = this.PontuacaoPIS(PIS);
        }

        this.setState({ PIS })

        this.validaPIS(PIS)
    }

    PontuacaoPIS(PIS) {
        let value = PIS.replace(/^([\d]{3})([\d]{5})([\d]{2})([\d]{1})$/, "$1.$2.$3-$4");

        return value
    }

    validaPIS(pisParam) {
        let PIS = this.state.PIS;
        if(pisParam){
            PIS = pisParam
        }

        if(PIS !== '')
        {
            PIS = PIS.replace(/\D/g,'');
            let multiplicadorBase = '3298765432';
            let total = 0;
            let resto = 0;
            let multiplicando = 0;
            let multiplicador = 0;
            let digito = 99;
            let validador = true;

            if (PIS === '') {
                validador = false;
            }

            else if (PIS.length !== 11 ||
                PIS === '00000000000' ||
                PIS === '11111111111' ||
                PIS === '22222222222' ||
                PIS === '33333333333' ||
                PIS === '44444444444' ||
                PIS === '55555555555' ||
                PIS === '66666666666' ||
                PIS === '77777777777' ||
                PIS === '88888888888' ||
                PIS === '99999999999') {
                validador = false;

            } else {
                for (let i = 0; i < 10; i++) {
                    multiplicando = parseInt(PIS.substring(i, i + 1));
                    multiplicador = parseInt(multiplicadorBase.substring(i, i + 1));
                    total += multiplicando * multiplicador;
                }

                resto = 11 - total % 11;
                resto = resto === 10 || resto === 11 ? 0 : resto;

                digito = parseInt('' + PIS.charAt(10));
                if (resto === digito) {
                    validador = true;
                }
                else {
                    validador = false;
                }
            }

            validador = validador && true;

            let PIS_erro = validador ? '' : 'PIS Inválido'
            this.setState({ PIS_erro, validador });
        }
        else{
            this.setState({
                PIS_erro: 'O PIS deve ser preenchido'
            })
        }
    }

    atribuiUserName(event) {
        this.setState({ UserName: event.target.value })

        this.validaUserName(event.target.value)
    }

    validaUserName(userNameParam) {
        let userName = this.state.UserName;
        let UserName_erro = '';

        if(userNameParam){
            userName = userNameParam
        }

        if(userName.length < 3){
            UserName_erro = 'Username precisa ter mais de 3 caracteres'
        }
        else if(!userName.match(/^[a-z0-9]+$/i)){
            UserName_erro = 'O UserName deve conter apenas letras e números'
        }

        this.setState({ UserName_erro })
    }

    atribuiAlteraSenha(check) {
        this.setState({ alterasenha: check })
    }

    atribuiFoto(check) {
        this.setState({ foto: check });
    }

    atribuiLocalizacao(check) {
        this.setState({ localizacao: check });
    }

    atribuiNumeroCarteira(event) {
        let numeroCarteira = event.target.value.replace(/[^\d]+/g, '');
        this.setState({ numeroCarteira })
    }

    atribuiSerieCarteira(event) {
        this.setState({ serieCarteira: event.target.value })
    }

    atribuiCargo(event) {
        this.setState({ cargo: event.target.value })
    }

    atribuiMatricula(event) {
        //Substitui tudo que não é numero por ''
        let matricula = event.target.value.replace(/\D/g,'')
        this.setState({ matricula })

        this.validaMatricula(matricula)
    }

    validaMatricula(matriculaParam){
        let matricula = this.state.matricula
        let matricula_erro = '';

        if(matriculaParam){
            matricula = matriculaParam
        }

        if(matricula.match(/\D/)){
            matricula_erro = 'Matrícula inválida'
        }
        else if(matricula.length === 0){
            matricula_erro = 'A matrícula deve ser preenchida'
        }

        this.setState({
            matricula_erro
        })

    }

    OnClickEnvia(event) {
        event.preventDefault();

        this.setState({ loading: true });

        //TODO arrumar essa gamibarra depois - Jorge
        if(this.state.UserName_erro === '' && this.state.CPF_erro === '' && this.state.PIS_erro === '' && 
        this.state.nome_erro === '' && this.state.matricula_erro === '')
        {
            if (this.state.IDColaborador === '') {
                this.requisicaoEnvio();
            }
            else {
                this.requisicaoAlteracao();
            }
        }
        else{
            this.setState({
                tipoErro: "Campos Inválidos",
                loading: false
            });
            this.ModalErro();
        }
    }

    requisicaoEnvio() {
        if (this.props.empresaSelecionada.value !== undefined) {
            let cookie = new Cookies();
            let token = cookie.get('token');
            //axios.post('https://192.168.1.62:5002/api/v1/' + this.props.empresaSelecionada.value + '/colaboradores',
            axios.post(process.env.REACT_APP_URL_REQUISICOES + this.props.empresaSelecionada.value + '/colaboradores',
            {
                Nome: this.state.nome,
                PIS: this.state.PIS,
                CPF: this.state.CPF,
                CTPS: this.state.numeroCarteira,
                Serie: this.state.serieCarteira,
                Matricula: this.state.matricula,
                Cargo: this.state.cargo,
                UserName: this.state.UserName,
                AlteraSenha: this.state.alterasenha,
                Foto: this.state.foto,
                Localizacao: this.state.localizacao
            },
            {

                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(res => {
                if (res.status === 201) {
                    this.ModalSucesso();

                    this.setState({
                        nome: '',
                        CPF: '',
                        PIS: '',
                        UserName: '',
                        alterasenha: false,
                        localizacao: false,
                        foto: false,
                        IDColaborador: '',
                        serieCarteira: '',
                        numeroCarteira: '',
                        cargo: '',
                        matricula: '',
                        mensagemSucesso: 'Cadastro feito com sucesso!',
                        loading: false
                    })
                }
            })
                .catch((error) => {
                    if (error.response !== undefined || error.response === '') {
                        switch (error.response.status) {
                            case 400:
                                this.setState({
                                    tipoErro: error.response.data,
                                    loading: false
                                });
                                this.ModalErro();
                                break;

                            case 401:
                                this.requisicaoRefresh(Requisicoes.POST);
                                break;

                            case 403:
                                this.setState({
                                    tipoErro: "Ação não autorizada",
                                    loading: false
                                });
                                this.ModalErro();
                                break;

                            case 408:
                                this.setState({
                                    tipoErro: 'Não foi possível conectar ao servidor',
                                    loading: false
                                });
                                this.ModalErro();
                                break;

                            default:
                                this.setState({
                                    tipoErro: "Erro não identificado. Contate o administrador!",
                                    loading: false
                                });
                                this.ModalRefresh();
                                break;
                        }
                    }
                    else {
                        this.setState({
                            tipoErro: "Erro não identificado. Contate o administrador!",
                            loading: false
                        });
                        this.ModalRefresh();
                    }
                })
        }
    }

    requisicaoAlteracao() {
        if (this.props.empresaSelecionada.value !== undefined) {
            let cookie = new Cookies();
            let token = cookie.get('token');

            //"https://192.168.1.62:5002:api/v1/"process.env.REACT_APP_URL_REQUISICOES_INTERNO
            axios.put(process.env.REACT_APP_URL_REQUISICOES + this.props.empresaSelecionada.value + '/colaboradores/' + this.state.IDColaborador,
                {
                    IDColaborador: this.state.IDColaborador,
                    Nome: this.state.nome,
                    PIS: this.state.PIS, //TODO: remover essa linha, não é mais necessário enviar o PIS
                    CPF: this.state.CPF,
                    CTPS: this.state.numeroCarteira,
                    Serie: this.state.serieCarteira,
                    Matricula: this.state.matricula,
                    Cargo: this.state.cargo,
                    UserName: this.state.UserName,
                    AlteraSenha: this.state.alterasenha,
                    Foto: this.state.foto,
                    Localizacao: this.state.localizacao
                },
                {

                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })
                .then(res => {
                    if (res.status === 201) {
                        this.ModalSucesso();

                        this.setState({
                            nome: '',
                            CPF: '',
                            PIS: '',
                            UserName: '',
                            alterasenha: false,
                            localizacao: false,
                            foto: false,
                            IDColaborador: '',
                            serieCarteira: '',
                            numeroCarteira: '',
                            cargo: '',
                            matricula: '',
                            mensagemSucesso: 'Alteração feita com sucesso!',
                            loading: false
                        })
                    }
                })
                .catch((error) => {
                    if (error.response !== undefined || error.response === '') {
                        switch (error.response.status) {
                            case 400:
                                this.setState({
                                    tipoErro: error.response.data,
                                    loading: false
                                });
                                this.ModalErro();
                                break;

                            case 401:
                                this.requisicaoRefresh(Requisicoes.PUT);
                                break;

                            case 403:
                                this.setState({
                                    tipoErro: "Ação não autorizada",
                                    loading: false
                                });
                                this.ModalErro();
                                break;

                            case 408:
                                this.setState({
                                    tipoErro: 'Não foi possível conectar ao servidor',
                                    loading: false
                                });
                                this.ModalErro();
                                break;

                            default:
                                this.setState({
                                    tipoErro: "Erro não identificado. Contate o administrador!",
                                    loading: false
                                });
                                this.ModalRefresh();
                                break;
                        }
                    }
                    else {
                        this.setState({
                            tipoErro: "Erro não identificado. Contate o administrador!",
                            loading: false
                        });
                        this.ModalRefresh();
                    }
                })
        }

    }

    requisicaoRefresh(tipoRequisicao) {
        let cookie = new Cookies();
        let token = cookie.get('token')
        let refreshToken = cookie.get('refreshToken')

        axios.post(process.env.REACT_APP_URL_REQUISICOES + 'auth/refresh',
            {
                refreshToken: refreshToken
            },
            {

                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                if (res.status === 200) {
                    const cookies = new Cookies();

                    cookies.set('token', res.data.token, { path: '/' });
                    cookies.set('refreshToken', res.data.refreshToken, { path: '/' });

                    switch (tipoRequisicao) {
                        case Requisicoes.POST:
                            this.requisicaoEnvio();
                            break;
                        case Requisicoes.PUT:
                            this.requisicaoAlteracao();
                            break;
                        default:
                            break;
                    }
                }
            })
            .catch(error => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        case 401:
                            this.setState({
                                tipoErro: 'Sessão Inválida',
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            })
    }

    ModalRefresh() {
        this.setState({ refresh: !this.state.refresh })
    }

    ModalSucesso() {
        this.setState({ sucesso: !this.state.sucesso })
    }

    ModalErro() {
        this.setState({ erro: !this.state.erro })
    }

    ModalCancelamento() {
        this.setState({ cancela: !this.state.cancela })
    }

    ModalLoading() {
        this.setState({ loading: !this.state.loading })
    }

    componentDidMount() {
        if (this.props.empresaSelecionada.value === undefined) {
            this.setState({ tipoErro: "Selecione uma empresa", mensagemModal: 'Atenção!' })
            this.ModalErro();
        }
    }

    render() {
        //Não vai ser possível alterar o PIS do funcionário. Alterado - Jorge
        var pisDisabled = this.state.IDColaborador !== '';

        return (
            <div className="row justify-content-center h-100 margemTop expansaoDiv">
                <Modal //modal de cancelamento
                    isOpen={this.state.cancela}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label> Suas informações serão perdidas. Deseja continuar? </label>
                        <div className="row">
                            <div className="col text-right">
                                <Link to='/Home/Colaborador/Tabela'><button className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Sim</button></Link>
                            </div>

                            <div className="col text-left">
                                <button onClick={this.ModalCancelamento} className="btn btn-outline-danger btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Não</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de sucesso
                    isOpen={this.state.sucesso}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label>{this.state.mensagemSucesso}</label><br />

                        <div className="row">
                            <div className="col">
                                <Link to="/Home/Colaborador/Tabela"><button onClick={this.ModalSucesso} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button></Link>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de erro
                    isOpen={this.state.erro}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center modalErro">
                        <label> {this.state.mensagemModal} </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p> {this.state.tipoErro} </p>}
                        <div className="row">
                            <div className="col">
                                <button onClick={this.ModalErro} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de erro refresh
                    isOpen={this.state.refresh}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center modalErro">
                        <label> Ops! Temos problemas: </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p> {this.state.tipoErro} </p>}
                        <div className="row">
                            <div className="col">
                                <Link to="/"><button onClick={this.ModalRefresh} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button></Link>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de Loading
                    isOpen={this.state.loading}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label> Carregando dados... </label><br />
                        <Spinner color="primary" size="lg" />
                    </div>
                </Modal>
                <div className="col-12 col-sm-11 col-md-9 col-lg-10 col-xl-8">
                    <div>
                        <div className="h4 text-center">
                            Colaborador
                        </div>

                        <form onSubmit={this.OnClickEnvia}>
                            <div className="form-row">
                                <div className="form-group col-12 col-sm-6">
                                    <label htmlFor="nome">Nome:</label>
                                    <input
                                        className={this.state.nome_erro ? 'form-control is-invalid' : 'form-control'}
                                        type="text"
                                        id="nome"
                                        name="nome"
                                        value={this.state.nome}
                                        onChange={this.atribuiNome}
                                        onBlur={() => this.validaNome(null)}
                                        maxLength="100"
                                        required="required"
                                        autoComplete="off"
                                    />
                                    <div className='invalid-feedback'>{this.state.nome_erro}</div>
                                </div>

                                <div className="form-group col-12 col-sm-6">
                                    <label htmlFor="PIS">PIS:</label>
                                    {/*Foi adicionado o campo disabled. Alterado - Jorge */}
                                    <input
                                        type="text"
                                        id="PIS"
                                        name="PIS"
                                        value={this.state.PIS}
                                        onChange={this.atribuiPIS}
                                        // onBlur={this.state.PIS !== '' ? () => this.validaPIS(null) : null}
                                        onBlur={() => this.validaPIS(null)}
                                        className={this.state.PIS_erro ? 'form-control is-invalid' : 'form-control'}
                                        maxLength="14"
                                        required="required"
                                        autoComplete="off"
                                        disabled ={pisDisabled}
                                    />
                                    <div className="invalid-feedback"> {this.state.PIS_erro}</div>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-12 col-sm-4">
                                    <label htmlFor="numeroCarteira">Carteira de Trabalho:</label>
                                    <input
                                        className='form-control'
                                        type="text"
                                        id="numeroCarteira"
                                        name="numeroCarteira"
                                        placeholder="Número"
                                        value={this.state.numeroCarteira}
                                        onChange={this.atribuiNumeroCarteira}
                                        maxLength="8"
                                        autoComplete="off"
                                    />
                                </div>

                                <div className="form-group col-12 col-sm-2">
                                    <label htmlFor="serieCarteira"> &nbsp; </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="serieCarteira"
                                        value={this.state.serieCarteira}
                                        onChange={this.atribuiSerieCarteira}
                                        maxLength="8"
                                        autoComplete="off"
                                        placeholder="Série"
                                    />
                                </div>

                                <div className="form-group col-12 col-sm-6">
                                    <label htmlFor="CPF"> CPF </label>
                                    <input
                                        type="text"
                                        id="CPF"
                                        name="CPF"
                                        maxLength='14'
                                        value={this.state.CPF}
                                        className={this.state.CPF_erro ? 'form-control is-invalid' : 'form-control'}
                                        onChange={this.atribuiCPF}
                                        onBlur={() => this.validaCPF(null)}
                                        // onBlur={this.state.CPF !== '' ? this.validaCPF : null}
                                        required='required'
                                        autoComplete='off'
                                    />
                                    <div className='invalid-feedback'>{this.state.CPF_erro}</div>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-12 col-sm-6">
                                    <label htmlFor="cargo"> Cargo </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="cargo"
                                        value={this.state.cargo}
                                        onChange={this.atribuiCargo}
                                        autoComplete="off"
                                        maxLength="25"
                                        required='required'
                                    />
                                </div>
                                <div className="form-group col-12 col-sm-6">
                                    <label htmlFor="matricula"> Matricula </label>
                                    <input
                                        type="text"
                                        className={this.state.matricula_erro ? 'form-control is-invalid' : 'form-control'}
                                        id="matricula"
                                        value={this.state.matricula}
                                        onChange={this.atribuiMatricula}
                                        onBlur={() => this.validaMatricula(null)}
                                        autoComplete="off"
                                        maxLength="20"
                                        required='required'
                                    />
                                    <div className="invalid-feedback"> {this.state.matricula_erro}</div>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-12 col-sm-6">
                                    <label htmlFor="UserName">UserName:</label>
                                    <input
                                        className={this.state.UserName_erro ? 'form-control is-invalid' : 'form-control'}
                                        type="text"
                                        id="UserName"
                                        name="UserName"
                                        value={this.state.UserName}
                                        onChange={this.atribuiUserName}
                                        // onBlur={this.state.UserName !== '' ? () => this.validaUserName(null) : null}
                                        onBlur={() => this.validaUserName(null)}
                                        maxLength="20"
                                        required="required"
                                        autoComplete="off"
                                    />
                                    <div className="invalid-feedback"> {this.state.UserName_erro}</div>
                                </div>

                                <div className="form-group col-12 col-sm-6">
                                    <label htmlFor="primeiroAcesso">Primeiro acesso</label>
                                    <div>
                                        <CheckBox
                                            id='primeiroAcesso'
                                            size={4}
                                            tickSize={3}
                                            borderThickness={1}
                                            checked={this.state.alterasenha}
                                            onChange={this.atribuiAlteraSenha}
                                            color={{ backgroundColor: '#007bef', borderColor: '#007bef', uncheckedBorderColor: '#ced4da' }}
                                            tickAnimationDuration={100}
                                            backAnimationDuration={100} />
                                    </div>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-2 col-sm-1">
                                    <label htmlFor='foto'>Foto</label>
                                    <div>
                                        <CheckBox
                                            id='foto'
                                            size={4}
                                            tickSize={3}
                                            borderThickness={1}
                                            checked={this.state.foto}
                                            onChange={this.atribuiFoto}
                                            color={{ backgroundColor: '#007bef', borderColor: '#007bef', uncheckedBorderColor: '#ced4da' }}
                                            tickAnimationDuration={100}
                                            backAnimationDuration={100} />
                                    </div>
                                </div>
                                <div className="form-group col-2 col-sm-1">
                                    <label htmlFor='localizacao'>Localização</label>
                                    <div>
                                        <CheckBox
                                            id='localizacao'
                                            size={4}
                                            tickSize={3}
                                            borderThickness={1}
                                            checked={this.state.localizacao}
                                            onChange={this.atribuiLocalizacao}
                                            color={{ backgroundColor: '#007bef', borderColor: '#007bef', uncheckedBorderColor: '#ced4da' }}
                                            tickAnimationDuration={100}
                                            backAnimationDuration={100} />
                                    </div>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col text-right">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-sm col-7 col-sm-6 col-md-5 col-lg-4 col-xl-4">
                                        Salvar
                                    </button>
                                </div>
                                <div className="form-group col text-left">
                                    <button
                                        type="button"
                                        onClick={this.ModalCancelamento}
                                        className="btn btn-danger btn-sm col-7 col-sm-6 col-md-5 col-lg-4 col-xl-4">
                                        Cancelar
                                        </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProp = (state) => ({
    empresaSelecionada: state.dadosEmpresa.empresaSelecionada
});

export default connect(mapStateToProp)(FormularioColaborador);