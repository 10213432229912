import React, { Component } from "react";

import BotaoForm from './botao_paraForm';
import Modal from 'react-modal';
import axios from 'axios';
import { Redirect } from 'react-router-dom'
import Cookies from 'universal-cookie';
import paginate from 'paginate-array';
import { connect } from 'react-redux';

import { Spinner } from 'reactstrap';
import { FaSistrix } from 'react-icons/fa';
import { MdNavigateBefore, MdLastPage, MdNavigateNext, MdFirstPage } from 'react-icons/md';
import { GoPencil, GoTrashcan } from "react-icons/go";

class TabelaUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            permissao: [],
            permissaoFiltrada: [],
            pesquisa: '',
            tipoPesquisa: '',
            size: 7,
            page: 1,
            currPage: null,
            exclusao: false,
            erro: false,
            refresh: false,
            tipoErro: [],
            mensagemModal: 'Ops! Temos problemas:',
            redirect: false,
            loading: false,
        }
        this.requisicaoListagem = this.requisicaoListagem.bind(this);
        this.mostraAtualizacao = this.mostraAtualizacao.bind(this);
        this.atribuiTipoPesquisa = this.atribuiTipoPesquisa.bind(this);
        this.atribuiPesquisa = this.atribuiPesquisa.bind(this);
        this.limpaPesquisa = this.limpaPesquisa.bind(this);
        this.filtro = this.filtro.bind(this);
        this.firstPage = this.firstPage.bind(this);
        this.lastPage = this.lastPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.buttonPaginacao = this.buttonPaginacao.bind(this);

        this.requisicaoRefresh = this.requisicaoRefresh.bind(this);

        this.ModalErro = this.ModalErro.bind(this);
        this.ModalDelete = this.ModalDelete.bind(this);
        this.ModalRefresh = this.ModalRefresh.bind(this);
        this.ModalLoading = this.ModalLoading.bind(this);

        this.requisicaoConsulta = this.requisicaoConsulta.bind(this);

        this.requisicaoDelete = this.requisicaoDelete.bind(this);
        this.requisicaoAtualizacao = this.requisicaoAtualizacao.bind(this);
    }

    ModalDelete(IDPermissao) {
        this.setState({ IDPermissao, exclusao: !this.state.exclusao });
    }

    ModalRefresh() {
        this.setState({ refresh: !this.state.refresh })
    }

    ModalLoading() {
        this.setState({ loading: !this.state.loading })
    }

    requisicaoDelete() {
        let cookie = new Cookies();
        let token = cookie.get('token');

        axios.delete(process.env.REACT_APP_URL_REQUISICOES + 'permissoes/' + this.state.IDPermissao,
            {
                 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                this.setState({ exclusao: false });
            });
        this.requisicaoAtualizacao();
    }

    requisicaoAtualizacao() {
        let permissao = this.state.permissao;
        let IDPermissao = this.state.IDPermissao;

        let posicao = permissao.findIndex(usuario => usuario.IDPermissao === IDPermissao);
        permissao.splice(posicao, 1);

        let { page, size } = this.state;

        let currPage = paginate(permissao, page, size);

        if (currPage.totalPages < page) {
            page = currPage.totalPages;
            currPage = paginate(permissao, page, size);
        }

        this.setState({
            permissao,
            page,
            currPage,
            permissaoFiltrada: permissao
        });
    }

    componentDidMount() {
        // Faz com que volte ao inicio da página
        window.scrollTo(0, 0);

        if (this.props.empresaSelecionada === '') {
            this.setState({ tipoErro: "Selecione uma empresa", mensagemModal: 'Atenção!' })
            this.ModalErro();
        }
        else {
            // Inicia a listagem  de permissao cadastradas
            this.requisicaoListagem();
        }
    }

    requisicaoConsulta(IDPermissao) {
        this.setState({
            loading: true
        });
        let cookie = new Cookies();
        let token = cookie.get('token');
        let permissoes;

        axios.get(process.env.REACT_APP_URL_REQUISICOES + 'permissoes/' + IDPermissao,
            {
                 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                permissoes = res.data;
                this.setState({
                    IDPermissao: IDPermissao,
                    UserName: permissoes.UserName,
                    // permissao: permissoes.Permissao,
                    redirect: true,
                    loading: false
                })
            })
            .catch((error) => {
                if (error !== undefined) {
                    if (error.response !== undefined) {
                        if (error.response.status === 401) {
                            this.requisicaoRefresh();
                        }
                        else if (error.response.status === 403) {
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalErro();
                        }
                        else {
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalErro();
                        }
                    }
                    else {
                        this.setState({
                            tipoErro: "Erro não identificado. Contate o administrador!",
                            loading: false
                        });
                        this.ModalRefresh();
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            })
    }

    requisicaoListagem() {
        this.setState({
            loading: true
        });
        let cookie = new Cookies();
        let token = cookie.get('token');

        axios.get(process.env.REACT_APP_URL_REQUISICOES + 'permissoes',
            {
                 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                const permissao = res.data;
                let { page, size } = this.state;

                const currPage = paginate(permissao, page, size);

                this.setState({
                    permissao,
                    currPage,
                    permissaoFiltrada: permissao,
                    loading: false
                });
            })
            .catch((error) => {
                if (error.response !== undefined) {
                    if (error.response.status === 401) {
                        this.requisicaoRefresh();
                    }
                    else if (error.response.status === 403) {
                        this.setState({
                            tipoErro: "Ação não autorizada",
                            loading: false
                        });
                        this.ModalErro();
                    }
                    else {
                        this.setState({
                            tipoErro: error.response.data,
                            loading: false
                        });
                        this.ModalErro();
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o adminitrador",
                        loading: false
                    });
                    this.ModalErro();
                }
            })
    }

    requisicaoRefresh() {
        let cookie = new Cookies();
        let token = cookie.get('token')
        let refreshToken = cookie.get('refreshToken')

        axios.post(process.env.REACT_APP_URL_REQUISICOES + 'auth/refresh',
            {
                refreshToken: refreshToken
            },
            {
                 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                if (res.status === 200) {
                    const cookies = new Cookies();

                    cookies.set('token', res.data.token, { path: '/' });
                    cookies.set('refreshToken', res.data.refreshToken, { path: '/' });
                    this.requisicaoListagem();
                }
            })
            .catch((error) => {
                this.setState({
                    tipoErro: error.response.data,
                    loading: false
                });
                this.ModalErro();
            })
    }

    ModalErro() {
        this.setState({ erro: !this.state.erro })
    }

    atribuiTipoPesquisa(event) {
        // Atribui o tipo de pesquisa que será usada no filtro
        let tipoPesquisa = event.target.value;
        this.setState({ tipoPesquisa, pesquisa: '' });
    }

    atribuiPesquisa(event) {
        // Atribui a pesquisa que será usada no filtro
        let pesquisa = event.target.value;
        this.setState({ pesquisa });
    }

    filtro(event) {
        //Faz a filtragem em sí
        event.preventDefault();
        let { tipoPesquisa } = this.state;
        let { pesquisa } = this.state;
        let permissaoFiltrada = [];

        if (tipoPesquisa === "descricao") {
            if (pesquisa !== '') {
                permissaoFiltrada = this.state.permissao.filter(permissao => {
                    return permissao.Descricao.toLowerCase().includes(pesquisa.toLowerCase());
                });
            }
            else {
                permissaoFiltrada = this.state.permissao;
            }
        }
        else if (pesquisa === "codPermissao") {
            permissaoFiltrada = this.state.permissao.filter(permissao => {
                return permissao.CodigoPermissao.includes(pesquisa);
            });
        }
        else {
            permissaoFiltrada = this.state.permissao;
        }

        this.setState({ permissaoFiltrada });
        this.mostraAtualizacao(permissaoFiltrada);
    }

    mostraAtualizacao(permissaoFiltrada) {
        //Atualiza as permissao que aparecem após a filtragem
        const size = this.state.size;

        const currPage = paginate(permissaoFiltrada, 1, size);

        this.setState({ currPage, page: 1 });
    }

    limpaPesquisa() {
        this.setState({ pesquisa: '' });
    }

    firstPage() {
        //Determina a próxima página
        const { size, permissaoFiltrada } = this.state;

        const newPage = 1;
        const newCurrPage = paginate(permissaoFiltrada, newPage, size);

        this.setState({
            page: newPage,
            currPage: newCurrPage
        });
    }

    lastPage() {
        //Determina a página anterior
        const { size, permissaoFiltrada } = this.state;

        if (this.state.currPage !== null) {
            const newPage = this.state.currPage.totalPages > 0 ? this.state.currPage.totalPages : 1;
            const newCurrPage = paginate(permissaoFiltrada, newPage, size);
            this.setState({
                page: newPage,
                currPage: newCurrPage
            });
        }
    }

    previousPage() {
        const { page, size, permissaoFiltrada } = this.state;

        if (page > 1) {
            const newPage = page - 1;
            const newCurrPage = paginate(permissaoFiltrada, newPage, size);

            this.setState({
                page: newPage,
                currPage: newCurrPage
            });
        }
    }

    nextPage() {
        const { currPage, page, size, permissaoFiltrada } = this.state;

        if (this.state.currPage !== null) {
            if (page < currPage.totalPages) {
                let newPage = page + 1;
                let newCurrPage = paginate(permissaoFiltrada, newPage, size);
                this.setState({
                    page: newPage,
                    currPage: newCurrPage
                });
            }
        }
    }

    buttonPaginacao(currPage) {
        if (currPage !== null) {
            if (currPage.totalPages === 1 || currPage.totalPages === 0) {
                return (
                    <button className="btn btn-outline-primary active">{this.state.page}</button>
                );
            }
            else if (currPage.currentPage === currPage.totalPages) {
                return (
                    <div className="btn-group">
                        <button className="btn btn-outline-primary" onClick={this.previousPage}>{this.state.page - 1}</button>
                        <button className="btn btn-outline-primary active">{this.state.page}</button>
                    </div>
                );
            }
            else if (this.state.page - 1 === 0) {
                return (
                    <div className="btn-group">
                        <button className="btn btn-outline-primary active">{this.state.page}</button>
                        <button className="btn btn-outline-primary" onClick={this.nextPage}>{this.state.page + 1}</button>
                    </div>
                );
            }
            else {
                return (
                    <div className="btn-group">
                        <button className="btn btn-outline-primary" onClick={this.previousPage}>{this.state.page - 1}</button>
                        <button className="btn btn-outline-primary active">{this.state.page}</button>
                        <button className="btn btn-outline-primary" onClick={this.nextPage}>{this.state.page + 1}</button>
                    </div>
                );
            }
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={{
                pathname: '/Home/Permissoes/NovaPermissao/Formulario',
                state: {

                }
            }} />
        }
        const { currPage } = this.state;
        return (
            <div className="h-100 margemTop">
                <Modal  //Modal de Loading
                    isOpen={this.state.loading}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label> Carregando dados... </label><br />
                        <Spinner color="primary" size="lg" />
                    </div>
                </Modal>
                <Modal //Modal de exclusão
                    isOpen={this.state.exclusao}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label> Certeza que deseja excluir esses dados? </label>
                        <div className="row">
                            <div className="col text-right">
                                <button onClick={this.requisicaoDelete} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Sim</button>
                            </div>

                            <div className="col text-left">
                                <button onClick={this.ModalDelete} className="btn btn-outline-danger btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Não</button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal  //Modal de erro
                    isOpen={this.state.erro}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center modalErro">
                        <label> Ops! Temos problemas: </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p> {this.state.tipoErro} </p>}
                        <div className="row">
                            <div className="col">
                                <button onClick={this.ModalErro} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <nav className="margemBottom">
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-11 col-md-9 col-lg-10 col-xl-8">
                            <div className="row">
                                <div className="col">
                                    <span className="navbar-brand"><label> Permissões </label></span>
                                </div>
                                <form>
                                    <div className="row">
                                        <div className="col-4 col-sm-5 col-md-4 col-lg-4">
                                            <select
                                                className="form-control input-group-prepend"
                                                type="select"
                                                name="tipopesquisa"
                                                required
                                                onChange={this.atribuiTipoPesquisa}>
                                                <option defaultValue> Selecione </option>
                                                <option value="codPermissao" className="input-group-text"> Código </option>
                                                <option value="descricao" className="input-group-text"> Descrição </option>
                                            </select>
                                        </div>
                                        <div className="col-8 col-sm-7 col-md-8 col-lg-8 tiraPadding">
                                            <div className="input-group-prepend">
                                                <div className="input-group">
                                                    <input id="filtro"
                                                        placeholder="Pesquisar"
                                                        type="text"
                                                        value={this.state.pesquisa}
                                                        className="form-control col-10"
                                                        onChange={this.atribuiPesquisa}
                                                        autoComplete="off" />
                                                    <button type="submit" className="input-group-text" onClick={this.filtro}><FaSistrix /></button>
                                                </div>
                                                <div className="col text-right tiraPadding">
                                                    <BotaoForm />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </nav>

                <div className="row justify-content-center h-100 expansaoDiv">
                    <div className="col-12 col-sm-11 col-md-9 col-lg-10 col-xl-8">
                        <div className="table-responsive">
                            <table className="table table-hover table-bordeless table-sm text-center">
                                <thead>
                                    <tr className="table table-primary">
                                        <th className="align-middle">Código</th>
                                        <th className="align-middle">Descrição</th>
                                        <th className="align-middle" colSpan="2"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currPage &&
                                        currPage.data.map(permissao =>
                                            <tr key={permissao.IDPermissao}>
                                                <td className="align-middle">{permissao.UserName}</td>
                                                <td className="align-middle">Nome da Permissão</td>
                                                <td>
                                                    <button className="btn" data-tip="Editar" onClick={() => this.requisicaoConsulta(permissao.IDPermissao)}><GoPencil size={20} /></button>
                                                </td>
                                                <td className="align-middle">
                                                    <button className="btn" data-tip="Excluir" onClick={() => this.ModalDelete(permissao.IDPermissao)}><GoTrashcan size={20} /></button>
                                                </td>
                                            </tr>
                                        )}
                                </tbody>
                            </table>
                        </div>
                        <div className="row justify-content-end right paginacao">
                            <div className="btn-toolbar">
                                <div className="btn-group">
                                    <button className="btn btn-outline-primary" onClick={this.firstPage}><MdFirstPage /></button>
                                    <button className="btn btn-outline-primary" onClick={this.previousPage}><MdNavigateBefore /></button>

                                    {this.buttonPaginacao(currPage)}

                                    <button className="btn btn-outline-primary" onClick={this.nextPage}><MdNavigateNext /></button>
                                    <button className="btn btn-outline-primary" onClick={this.lastPage}><MdLastPage /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

const mapStateToProp = (state) => ({
    empresaSelecionada: state.dadosEmpresa.empresaSelecionada
});

export default connect(mapStateToProp)(TabelaUser);

