import React, { Component } from 'react';
import AFDFiscal from './AFDFiscal';

export default class MainAFD extends Component {
    render() {
        return (
            <div>
                <div className="row justify-content-center h-100 expansaoDiv">
                    <div className="col-12 col-sm-11 col-md-9 col-lg-10 col-xl-8">
                        <AFDFiscal/>
                    </div>
                </div>
            </div>
        );
    }
}