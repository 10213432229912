import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { FaPlus } from "react-icons/fa";

export default class BotaoForm extends Component {
    render() {
        return (
            <Link to={{
                pathname: '/Home/Empresa/NovaEmpresa/Formulario',
                state: {
                    IDEmpresa: '',
                    nomeFantasia: '',
                    razaoSocial: '',
                    CPF_CNPJ: '',
                    endereco: '',
                    atividadeEconomica: ''
                }
            }}>
                <button className="btn btn-outline-primary">
                    <FaPlus size={20} className="icone" />
                </button>
            </Link>
        );
    }
}