import React, { Component } from 'react';
// import { FaSignOutAlt, FaStore, FaUserTie, FaRegIdBadge, FaFileDownload } from "react-icons/fa";
import { FaSignOutAlt, FaStore, FaUserTie, FaRegIdBadge, FaFileAlt, FaFileUpload } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem } from 'reactstrap';
import Cookies from 'universal-cookie';
import axios from 'axios';
import Modal from 'react-modal';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { changeReset, changeIDEmpresa, changeEmpresas, changeLogout } from './actions/actionsIDEmpresa';
import Select from 'react-select';

Modal.setAppElement('#root');

const ROLE_Admin = "Admin";
const ROLE_RH = "RH";
const ROLE_User = "User";

const customizacao =
    theme => ({
        ...theme,
        borderRadius: 5,
        colors: {
            ...theme.colors,
            primary25: "#009bfe", //cor do hover de itens
            neutral30: "#fff", //cor da borda do select ao passar o mouse
            neutral0: "#007bfe", //cor do select
            neutral50: "#fff", //cor do placeholder select
            neutral80: "#fff"
        }
    });

class NavbarSistema extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            sair: false,
            refresh: false,
            erro: false,
            redirect: false,
            options: [],
            empresaSelecionada: '',
            tipoErro: []
        }
        this.setIsOpen = this.setIsOpen.bind(this);
        this.fechaToggle = this.fechaToggle.bind(this);

        this.ModalSair = this.ModalSair.bind(this);
        this.ModalRefresh = this.ModalRefresh.bind(this);
        this.ModalErro = this.ModalErro.bind(this);

        this.sair = this.sair.bind(this);
        this.reloadPagina = this.reloadPagina.bind(this);

        this.requisicaoSelect = this.requisicaoSelect.bind(this);
        this.requisicaoRefresh = this.requisicaoRefresh.bind(this);

        this.atribuiEmpresa = this.atribuiEmpresa.bind(this);

        this.verificaRole = this.verificaRole.bind(this);
        this.verificaRoleUser = this.verificaRoleUser.bind(this);
    }

    verificaRole() {
        switch (this.props.role) {
            case ROLE_Admin:
                return true;

            case ROLE_RH:
                return false;

            case ROLE_User:
                return false;

            default:
                return false;
        }
    }

    verificaRoleUser() {
        switch (this.props.role) {
            case ROLE_Admin:
                return true;

            case ROLE_RH:
                return true;

            case ROLE_User:
                return false;

            default:
                return false;
        }
    }

    atribuiEmpresa(empresaSelecionada) {
        this.setState({ empresaSelecionada })
        this.props.changeIDEmpresa(empresaSelecionada);
        setTimeout(this.reloadPagina, 500); //estipula tempo para chamar função
    };

    reloadPagina() {
        window.location.reload(true); //força reload na página
    }

    componentDidMount() {
        this.requisicaoSelect();
    }

    requisicaoSelect() {
        let cookie = new Cookies();
        let token = cookie.get('token');

        // axios.get(process.env.REACT_APP_URL_BASENAME + 'empresas',
        axios.get(process.env.REACT_APP_URL_REQUISICOES_V2 + 'empresas',
            {

                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                const empresas = res.data;
                let options = [];
                // Mapeia os dados retornados pela requisição e atribui a um array
                empresas.map(empresa => {
                    options.push(
                        { value: empresa.IDEmpresa, label: empresa.NomeFantasia }
                    )
                    return null //return usado para evitar exibição de warning no console
                })
                this.setState({ options });
                this.props.changeEmpresas(options);
            })
            .catch((error) => {
                if (error !== undefined) {
                    if (error.response !== undefined) {
                        if (error.response.status === 401) {
                            this.requisicaoRefresh();
                        }
                        else if (error.response.status === 403) {
                            this.setState({
                                tipoErro: "Ação não autorizada"
                            });
                            this.ModalErro();
                        }
                    }
                    else {
                        this.setState({
                            tipoErro: "Erro não identificado. Contate o administrador"
                        });
                        this.ModalRefresh();
                    }
                }
            })
    }

    requisicaoRefresh() {
        let cookie = new Cookies();
        let token = cookie.get('token')
        let refreshToken = cookie.get('refreshToken')

        axios.post(process.env.REACT_APP_URL_REQUISICOES + 'auth/refresh',
            {
                refreshToken: refreshToken
            },
            {

                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                if (res.status === 200) {
                    const cookies = new Cookies();

                    cookies.set('token', res.data.token, { path: '/' });
                    cookies.set('refreshToken', res.data.refreshToken, { path: '/' });
                    this.requisicaoSelect();
                }
            })

            .catch((error) => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        case 401:
                            this.setState({
                                tipoErro: 'Sessão Inválida',
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        case 408:
                            this.setState({
                                tipoErro: 'Não foi possível conectar ao servidor',
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            })
    }

    setIsOpen() {
        let isOpen = this.state.isOpen
        this.setState({ isOpen: !isOpen })
    }

    ModalSair() {
        this.setState({ sair: !this.state.sair });
    }

    ModalRefresh() {
        this.setState({ refresh: !this.state.refresh });
    }

    ModalErro() {
        this.setState({ erro: !this.state.erro })
    }

    sair() {
        let cookies = new Cookies('token');
        cookies.remove('token', { path: '/' });
        cookies.remove('refreshToken', { path: '/' });

        if (this.props.conectado === false) {
            this.props.changeLogout();
        }

        this.props.changeReset();
        this.setState({ redirect: true })
    }

    fechaToggle() {
        this.setState({ isOpen: false })
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to="/" />
        }
        return (
            <div>
                <Modal //modal de cancelamento
                    isOpen={this.state.sair}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label> Suas informações serão perdidas. Deseja continuar? </label>
                        <div className="row">
                            <div className="col text-right">
                                <button onClick={this.sair} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Sim</button>
                            </div>

                            <div className="col text-left">
                                <button onClick={this.ModalSair} className="btn btn-outline-danger btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Não</button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal  //Modal de refresh
                    isOpen={this.state.refresh}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center modalErro">
                        <label> Ops! Temos problemas: </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p> {this.state.tipoErro} </p>}
                        <div className="row">
                            <div className="col">
                                <Link to='/'><button onClick={this.ModalRefresh} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button></Link>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal  //Modal de erro
                    isOpen={this.state.erro}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center modalErro">
                        <label> Ops! Temos problemas:  </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p> {this.state.tipoErro} </p>}
                        <div className="row">
                            <div className="col">
                                <button onClick={this.ModalErro} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Navbar color="primary" dark expand="lg" onBlur={this.fechaToggle}>
                    <NavbarToggler onClick={this.setIsOpen} />
                    <NavbarBrand className="mr-auto col-6 col-sm-8 col-md-8 col-lg-3">
                        <Select
                            value={this.props.empresaSelecionada} //valor atual
                            onChange={this.atribuiEmpresa}
                            options={this.props.options} //opções do select
                            theme={customizacao}
                        />
                    </NavbarBrand>
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            <NavItem className="margemNavlink">
                                <Link to="/Home/Empresa/Tabela" className="text-light"><FaStore /> Empresa </Link>
                            </NavItem>
                            <NavItem className="margemNavlink">
                                <Link to="/Home/Colaborador/Tabela" className="text-light"><FaUserTie /> Colaborador </Link>
                            </NavItem>
                            {this.verificaRole()
                                ?
                                <NavItem className="margemNavlink">
                                    <Link to="/Home/User/Tabela" className="text-light"><FaRegIdBadge /> Usuários </Link>
                                </NavItem>
                                :
                                null
                            }
                            {/* {this.verificaRoleUser()
                                ? */}
                                <NavItem className="margemNavlink">
                                    <Link to="/Home/Relatorio/" className="text-light"><FaFileAlt /> Relatórios </Link>
                                </NavItem>
                                {/* // :
                                // null} */}
                            {/* {this.verificaRoleUser()
                                ?
                                < NavItem className="margemNavlink">
                                    <Link to="/Home/AFDFiscal/" className="text-light"><FaFileDownload /> AFD Fiscal </Link>
                                </NavItem>
                                :
                                null
                            } */}
                            {this.verificaRoleUser()
                                ?
                                <NavItem className="margemNavlink">
                                    <Link to="/Home/UploadArquivo/" className="text-light"><FaFileUpload /> Importação </Link>
                                </NavItem>
                                :
                                null
                            }
                            <NavItem className="margemNavlink">
                                <Link to="/Home/Marcacoes" className="text-light"><FaUserTie /> Marcações </Link>
                            </NavItem>
                        </Nav>
                    </Collapse>
                    <NavItem className="tiraMarcador">
                        <button onClick={this.ModalSair} className="btn btn-link text-light"><FaSignOutAlt /> Sair </button>
                    </NavItem>
                </Navbar>
            </div >
        );
    }
}

const mapStateToProp = (state) => ({
    empresaSelecionada: state.dadosEmpresa.empresaSelecionada,
    options: state.dadosEmpresa.options,
    role: state.dadosEmpresa.role,
    conectado: state.dadosEmpresa.conectado,
    id: state.dadosEmpresa.id,
    username: state.dadosEmpresa.username
});

const mapActionstoProps = {
    changeReset,
    changeIDEmpresa,
    changeEmpresas,
    changeLogout
}

export default connect(mapStateToProp, mapActionstoProps)(NavbarSistema);