import React, { Component } from "react";
import { Link } from 'react-router-dom'
import Modal from 'react-modal';
import axios from 'axios';
import Cookies from 'universal-cookie'
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';

Modal.setAppElement('#root');

class Upload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            erro: false,
            cancela: false,
            refresh: false,
            sucesso: false,

            mensagemSucesso: '',
            tipoErro: [],
            mensagemModal: 'Ops! Temos problemas:',
            redirect: false,
            log: 'Nenhum resultado disponivel',
            arquivo: ''
        }
        this.enviaDados = this.enviaDados.bind(this);

        this.ModalErro = this.ModalErro.bind(this);
        this.ModalCancelamento = this.ModalCancelamento.bind(this);
        this.ModalRefresh = this.ModalRefresh.bind(this);
        this.ModalSucesso = this.ModalSucesso.bind(this);

        this.requisicaoRefresh = this.requisicaoRefresh.bind(this);
        this.OnClickEnvia = this.OnClickEnvia.bind(this);

        this.atribuiArquivo = this.atribuiArquivo.bind(this);

    }

    atribuiArquivo(event) {
        let file = event.target.files[0];
        this.setState({ arquivo: file })
    }

    componentDidMount() {
        if (this.props.empresaSelecionada.value === undefined) {
            this.setState({
                tipoErro: 'Selecione uma Empresa',
                mensagemModal: 'Atenção!',
            });
            this.ModalErro();
        }
    }

    OnClickEnvia(event) {
        event.preventDefault();
        if (this.props.empresaSelecionada === '') {
            this.setState({ tipoErro: "Selecione uma empresa", mensagemModal: 'Atenção!' })
            this.ModalErro();
        }
        else {
            this.setState({loading: true})
            this.enviaDados();
        }
    }

    enviaDados() {
        let cookie = new Cookies();
        let token = cookie.get('token');

        let formData = new FormData();

        formData.append('arquivo', this.state.arquivo);

        axios.post(process.env.REACT_APP_URL_REQUISICOES + this.props.empresaSelecionada.value + '/importacoes/colaboradores', formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                let resultado = res.data;
                resultado = resultado.join("\n")
                this.setState({ log: resultado, loading: false })
            })
            .catch((error) => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 401:
                            this.requisicaoRefresh();
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 408:
                            this.setState({
                                tipoErro: 'Não foi possível conectar ao servidor',
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            })
    }

    requisicaoRefresh() {
        let cookie = new Cookies();
        let token = cookie.get('token')
        let refreshToken = cookie.get('refreshToken')

        axios.post(process.env.REACT_APP_URL_REQUISICOES + 'auth/refresh',
            {
                refreshToken: refreshToken
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                if (res.status === 200) {
                    const cookies = new Cookies();

                    cookies.set('token', res.data.token, { path: '/' });
                    cookies.set('refreshToken', res.data.refreshToken, { path: '/' });
                    this.enviaDados();
                }
            })
            .catch((error) => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        case 401:
                            this.setState({
                                tipoErro: 'Sessão Inválida',
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            })
    }

    ModalRefresh() {
        this.setState({ refresh: !this.state.refresh })
    }

    ModalErro() {
        this.setState({ erro: !this.state.erro })
    }

    ModalCancelamento() {
        this.setState({ cancela: !this.state.cancela })
    }

    ModalSucesso() {
        this.setState({ sucesso: !this.state.sucesso })
    }

    render() {
        return (
            <div className="row justify-content-center h-100 margemTop">
                <Modal //modal de cancelamento
                    isOpen={this.state.cancela}
                    //onRequestClose={this.ModalCancelamento}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label> Suas informações serão perdidas. Deseja continuar? </label>
                        <div className="row">
                            <div className="col text-right">
                                <button onClick={this.apagaDados} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Sim</button>
                            </div>

                            <div className="col text-left">
                                <button onClick={this.ModalCancelamento} className="btn btn-outline-danger btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Não</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de erro
                    isOpen={this.state.erro}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center modalErro">
                        <label> {this.state.mensagemModal} </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p> {this.state.tipoErro} </p>}
                        <div className="row">
                            <div className="col">
                                <button onClick={this.ModalErro} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de erro refresh
                    isOpen={this.state.refresh}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center modalErro">
                        <label> Ops! Temos problemas: </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p> {this.state.tipoErro} </p>}
                        <div className="row">
                            <div className="col">
                                <Link to="/"><button onClick={this.ModalRefresh} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button></Link>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de sucesso
                    isOpen={this.state.sucesso}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label>{this.state.mensagemSucesso}</label><br />

                        <div className="row">
                            <div className="col">
                                <Link to="/Home/Colaborador/Tabela"><button onClick={this.ModalSucesso} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button></Link>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de Loading
                    isOpen={this.state.loading}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label> Carregando dados... </label><br />
                        <Spinner color="primary" size="lg" />
                    </div>
                </Modal>

                <div className="col-12 col-sm-11 col-md-9 col-lg-10 col-xl-8">
                    <div>
                        <div className="h4 text-center">
                            Importação de Colaboradores
                        </div>

                        <form onSubmit={this.OnClickEnvia} encType="multipart/form-data">
                            <div className="form-row">
                                <div className="form-group col-12 col-sm-6 text-left margemTop">
                                    <input
                                        type="file"
                                        id="arquivo"
                                        name="arquivo"
                                        className="col-12"
                                        onChange={this.atribuiArquivo}
                                    />
                                </div>
                                <div className="form-group col-12 col-sm-6 text-center margemTop">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-sm col-7 col-sm-6 col-md-5 col-lg-4 col-xl-4">
                                        Upload
                                    </button>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col text-right margemTop">
                                    <textarea
                                        className="form-control tamanhoTextarea"
                                        readOnly
                                        value={this.state.log}
                                        rows="5"
                                        cols="5">
                                    </textarea>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        );
    }
}

const mapStateToProp = (state) => ({
    empresaSelecionada: state.dadosEmpresa.empresaSelecionada
});

export default connect(mapStateToProp)(Upload);