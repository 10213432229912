import React, { Component } from "react";
import { Link } from 'react-router-dom'
import Modal from 'react-modal';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';
import { changeEmpresas } from '../Home/actions/actionsIDEmpresa';

Modal.setAppElement('#root');

const Requisicoes = Object.freeze({
    POST: 'POST',
    PUT: 'PUT'
});

class Formulario extends Component {
    constructor(props) {
        super(props);

        this.state = {
            nomeFantasia: this.props.location.state.nomeFantasia,
            nomeFantasia_erro: '',

            razaoSocial: this.props.location.state.razaoSocial,
            razaoSocial_erro: '',

            CPF_CNPJ: this.props.location.state.CPF_CNPJ,
            CPF_CNPJ_erro: '',

            endereco: this.props.location.state.endereco,
            endereco_erro: '',

            IDEmpresa: this.props.location.state.IDEmpresa,

            atividadeEconomica: this.props.location.state.atividadeEconomica,
            atividade_erro:'',

            validador: false,
            erro: false,
            cancela: false,
            sucesso: false,
            refresh: false,
            mensagemSucesso: '',
            loading: false,
            tipoErro: []
        }

        this.atribuiNomeFantasia = this.atribuiNomeFantasia.bind(this);
        this.validaNomeFantasia = this.validaNomeFantasia.bind(this);

        this.atribuiRazaoSocial = this.atribuiRazaoSocial.bind(this);
        this.validaRazaoSocial = this.validaRazaoSocial.bind(this);

        this.atribuiCPF_CNPJ = this.atribuiCPF_CNPJ.bind(this);
        this.validaCPF_CNPJ = this.validaCPF_CNPJ.bind(this);

        this.validaCPF = this.validaCPF.bind(this);
        this.validaCNPJ = this.validaCNPJ.bind(this);

        this.atribuiEndereco = this.atribuiEndereco.bind(this);
        this.validaEndereco = this.validaEndereco.bind(this);

        this.atribuiAtividadeEconomica = this.atribuiAtividadeEconomica.bind(this);
        this.validaAtividadeEconomica = this.validaAtividadeEconomica.bind(this);

        this.ModalSucesso = this.ModalSucesso.bind(this);
        this.ModalErro = this.ModalErro.bind(this);
        this.ModalCancelamento = this.ModalCancelamento.bind(this);
        this.ModalRefresh = this.ModalRefresh.bind(this);
        this.ModalLoading = this.ModalLoading.bind(this);

        this.requisicaoRefresh = this.requisicaoRefresh.bind(this);
        this.OnClickEnvia = this.OnClickEnvia.bind(this);
        this.pontuacaoCPF = this.pontuacaoCPF.bind(this);
        this.pontuacaoCNPJ = this.pontuacaoCNPJ.bind(this);

        this.requisicaoEnvio = this.requisicaoEnvio.bind(this);
        this.requisicaoAlteracao = this.requisicaoAlteracao.bind(this);
    }

    OnClickEnvia(event) {
        event.preventDefault();
        this.setState({ loading: true })

        if(this.state.nomeFantasia_erro === '' && this.state.atividade_erro === '' && 
        this.state.CPF_CNPJ_erro === '' && this.state.razaoSocial_erro === '' && this.state.endereco_erro === '')
        {
            if (this.state.IDEmpresa === '') {
                this.requisicaoEnvio()
            }
            else {
                this.requisicaoAlteracao();
            }
        }
        else{
            this.setState({
                tipoErro: 'Campos inválidos',
                loading: false
            });
            this.ModalErro();
        }
    }

    requisicaoEnvio() {
        this.setState({ loading: true })
        let cookie = new Cookies();
        let token = cookie.get('token');

        // axios.post(process.env.REACT_APP_URL_BASENAME + 'empresas',
        axios.post(process.env.REACT_APP_URL_REQUISICOES_V2 + 'empresas',
            {
                NomeFantasia: this.state.nomeFantasia,
                RazaoSocial: this.state.razaoSocial,
                CNPJ_CPF: this.state.CPF_CNPJ,
                Endereco: this.state.endereco,
                AtividadeEconomica: this.state.atividadeEconomica
            },
            {

                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                if (res.status === 201) {
                    this.ModalSucesso();

                    this.setState({
                        nomeFantasia: '',
                        razaoSocial: '',
                        CPF_CNPJ: '',
                        endereco: '',
                        mensagemSucesso: 'Cadastro feito com sucesso!',
                        loading: false
                    });

                    let optionsArray = this.props.options;

                    let optionObject = {
                        value: res.data.idEmpresa,
                        label: res.data.nomeFantasia
                    }

                    optionsArray.push(optionObject);

                    this.props.changeEmpresas(optionsArray);
                }
            })
            .catch((error) => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 401:
                            this.requisicaoRefresh(Requisicoes.POST);
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 408:
                            this.setState({
                                tipoErro: 'Não foi possível conectar ao servidor',
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            })
    }

    requisicaoAlteracao() {
        this.setState({ loading: true })
        let cookie = new Cookies();
        let token = cookie.get('token');

        axios.put(process.env.REACT_APP_URL_REQUISICOES_V2 + 'empresas/' + this.state.IDEmpresa,
            {
                IDEmpresa: this.state.IDEmpresa,
                NomeFantasia: this.state.nomeFantasia,
                RazaoSocial: this.state.razaoSocial,
                CNPJ_CPF: this.state.CPF_CNPJ,
                Endereco: this.state.endereco,
                AtividadeEconomica: this.state.atividadeEconomica
            },
            {

                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                if (res.status === 201) {
                    this.ModalSucesso();

                    this.setState({
                        nomeFantasia: '',
                        razaoSocial: '',
                        CPF_CNPJ: '',
                        endereco: '',
                        mensagemSucesso: 'Alteração feita com sucesso!',
                        loading: false
                    })
                }
            })
            .catch((error) => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 401:
                            this.requisicaoRefresh(Requisicoes.PUT);
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 408:
                            this.setState({
                                tipoErro: 'Não foi possível conectar ao servidor',
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            })
    }

    requisicaoRefresh(tipoRequisicao) {
        let cookie = new Cookies();
        let token = cookie.get('token')
        let refreshToken = cookie.get('refreshToken')

        axios.post(process.env.REACT_APP_URL_REQUISICOES + 'auth/refresh',
            {
                refreshToken: refreshToken
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                if (res.status === 200) {
                    const cookies = new Cookies();

                    cookies.set('token', res.data.token, { path: '/' });
                    cookies.set('refreshToken', res.data.refreshToken, { path: '/' });

                    switch (tipoRequisicao) {
                        case Requisicoes.POST:
                            this.requisicaoEnvio();
                            break;
                        case Requisicoes.PUT:
                            this.requisicaoAlteracao();
                            break;
                        default:
                            break;
                    }
                }
            })
            .catch(error => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        case 401:
                            this.setState({
                                tipoErro: 'Sessão Inválida',
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            })
    }

    ModalRefresh() {
        this.setState({ refresh: !this.state.refresh })
    }

    ModalSucesso() {
        this.setState({ sucesso: !this.state.sucesso })
    }

    ModalErro() {
        this.setState({ erro: !this.state.erro })
    }

    ModalCancelamento() {
        this.setState({ cancela: !this.state.cancela })
    }

    ModalLoading() {
        this.setState({ loading: !this.state.loading })
    }

    atribuiNomeFantasia(event) {
        let nomeFantasia = event.target.value;
        this.setState({ nomeFantasia })

        this.validaNomeFantasia(nomeFantasia)
    }

    validaNomeFantasia(nomeParam) {
        let nomeFantasia = this.state.nomeFantasia;
        let nomeFantasia_erro = '';

        if(nomeParam){
            nomeFantasia = nomeParam
        }

        if(nomeFantasia.length < 4 || nomeFantasia.length > 100){
            nomeFantasia_erro = 'Nome fantasia precisa ter entre 4 a 100 caracteres'
        }

        this.setState({ nomeFantasia_erro })
    }

    atribuiRazaoSocial(event) {
        let razaoSocial = event.target.value;
        this.setState({ razaoSocial })

        this.validaRazaoSocial(razaoSocial);
    }

    validaRazaoSocial(razaoParam) {
        let razaoSocial = this.state.razaoSocial;
        let razaoSocial_erro = '';

        if(razaoParam || razaoParam === ''){
            razaoSocial = razaoParam
        }

        if(razaoSocial.length < 4 || razaoSocial.length > 50){
            razaoSocial_erro = 'Nome fantasia precisa ter entre 4 a 50 caracteres'
        }

        this.setState({ razaoSocial_erro })
    }

    atribuiCPF_CNPJ(event) {
        let CPF_CNPJ = event.target.value;

        CPF_CNPJ = CPF_CNPJ.replace(/\D/g, '');

        // if (CPF_CNPJ.length === 11) {
        //     CPF_CNPJ = this.pontuacaoCPF(CPF_CNPJ);
        // }
        // else if (CPF_CNPJ.length === 14) {
        //     CPF_CNPJ = this.pontuacaoCNPJ(CPF_CNPJ);
        // }

        this.setState({ CPF_CNPJ })
    }

    pontuacaoCPF(CPF_CNPJ) {
        let CPF = CPF_CNPJ.replace(/^([\d]{3})([\d]{3})([\d]{3})([\d]{2})$/, "$1.$2.$3-$4");

        return CPF
    }

    pontuacaoCNPJ(CPF_CNPJ) {
        let CNPJ = CPF_CNPJ.replace(/^([\d]{2})([\d]{3})([\d]{3})([\d]{4})([\d]{2})$/, "$1.$2.$3/$4-$5");

        return CNPJ
    }

    validaCPF_CNPJ() {
        let CPF_CNPJ = this.state.CPF_CNPJ;

        // CPF_CNPJ = CPF_CNPJ.replace('.', '').replace('.', '').replace('-', '').replace('/', '');
        CPF_CNPJ = CPF_CNPJ.replace(/\D/g, '');

        if (CPF_CNPJ.length === 11) {
            this.validaCPF(CPF_CNPJ);
            CPF_CNPJ = this.pontuacaoCPF(CPF_CNPJ)
        }
        else if (CPF_CNPJ.length === 14) {
            this.validaCNPJ(CPF_CNPJ);
            CPF_CNPJ = this.pontuacaoCNPJ(CPF_CNPJ)
        }
        else {
            this.setState({CPF_CNPJ_erro: "CPF/CNPJ Inválido"})
        }

        this.setState({ CPF_CNPJ })
    }

    validaCPF(CPF_CNPJ) {
        var validador_cpf = true;

        if (CPF_CNPJ === "00000000000" ||
            CPF_CNPJ === "11111111111" ||
            CPF_CNPJ === "22222222222" ||
            CPF_CNPJ === "33333333333" ||
            CPF_CNPJ === "44444444444" ||
            CPF_CNPJ === "55555555555" ||
            CPF_CNPJ === "66666666666" ||
            CPF_CNPJ === "77777777777" ||
            CPF_CNPJ === "88888888888" ||
            CPF_CNPJ === "99999999999") {
            validador_cpf = false;
        }
        else {
            var add = 0;
            for (var j = 0; j < 9; j++) {
                add += parseInt(CPF_CNPJ.charAt(j)) * (10 - j);
            }
            var rev = (add % 11);

            if (rev < 2)
                rev = 0;
            else
                rev = 11 - rev;

            if (rev !== parseInt(CPF_CNPJ.charAt(9))) {
                validador_cpf = false;
            }

            add = 0;
            for (var i = 0; i < 10; i++) {
                add += parseInt(CPF_CNPJ.charAt(i)) * (11 - i);
                rev = 11 - (add % 11);
            }

            if (rev === 10 || rev === 11) {
                rev = 0;
            }

            else if (rev !== parseInt(CPF_CNPJ.charAt(10))) {
                validador_cpf = false;
            }
        }

        if (validador_cpf) {
            this.setState({ CPF_CNPJ_erro: '' });
        }
        else {
            this.setState({ CPF_CNPJ_erro: "CPF/CNPJ Inválido" })
        }
    }

    validaCNPJ(CPF_CNPJ) {
        var validador_cnpj = true;

        if (CPF_CNPJ === "00000000000000" ||
            CPF_CNPJ === "11111111111111" ||
            CPF_CNPJ === "22222222222222" ||
            CPF_CNPJ === "33333333333333" ||
            CPF_CNPJ === "44444444444444" ||
            CPF_CNPJ === "55555555555555" ||
            CPF_CNPJ === "66666666666666" ||
            CPF_CNPJ === "77777777777777" ||
            CPF_CNPJ === "88888888888888" ||
            CPF_CNPJ === "99999999999999") {

            validador_cnpj = false;
        }
        else {
            const multiplicador1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
            const multiplicador2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
            let soma = 0;
            let digito1;
            let digito2;

            for (let i = 0; i < 12; i++) {
                soma += multiplicador1[i] * parseInt(CPF_CNPJ[i]);
            }

            let resto = soma % 11;

            if (resto < 2) {
                digito1 = 0;
            }
            else {
                digito1 = 11 - resto;
            }

            soma = 0;

            for (let i = 0; i < 13; i++) {
                soma += multiplicador2[i] * parseInt(CPF_CNPJ[i]);
            }

            resto = soma % 11;

            if (resto < 2) {
                digito2 = 0;
            }
            else {
                digito2 = 11 - resto;
            }
            if (parseInt(CPF_CNPJ[12]) === digito1 && parseInt(CPF_CNPJ[13]) === digito2) {
                validador_cnpj = true;
            }
            else {
                validador_cnpj = false;
            }
        }

        if (validador_cnpj) {
            this.setState({ CPF_CNPJ_erro: '' });
        }
        else {
            this.setState({ CPF_CNPJ_erro: "CPF/CNPJ Inválido" });
        }
    }

    atribuiEndereco(event) {
        let endereco = event.target.value;
        this.setState({ endereco })

        this.validaEndereco(endereco);
    }

    validaEndereco(enderecoParam){
        let endereco = this.state.endereco;
        let endereco_erro = ''

        if(enderecoParam || enderecoParam === ''){
            endereco = enderecoParam
        }

        if(endereco.length === 0)
        {
            endereco_erro = 'O endereço deve ser preenchido';
        }
        
        this.setState({
            endereco_erro
        })
    }

    atribuiAtividadeEconomica(event) {
        let atividadeEconomica = event.target.value
        this.setState({ atividadeEconomica })

        this.validaAtividadeEconomica(atividadeEconomica)
    }

    validaAtividadeEconomica(atividadeParam){
        let atividade = this.state.atividadeEconomica;
        let atividade_erro = ''

        if(atividadeParam || atividadeParam === ''){
            atividade = atividadeParam
        }

        atividade_erro = atividade.length === 0 ? 'A atividade econômica deve ser preenchida' : ''
        
        this.setState({
            atividade_erro
        })
    }

    render() {
        return (
            <div className="row justify-content-center h-100 margemTop expansaoDiv">
                <Modal //modal de cancelamento
                    isOpen={this.state.cancela}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label> Suas informações serão perdidas. Deseja continuar? </label>
                        <div className="row">
                            <div className="col text-right">
                                <Link to='/Home/Empresa/Tabela'><button className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Sim</button></Link>
                            </div>

                            <div className="col text-left">
                                <button onClick={this.ModalCancelamento} className="btn btn-outline-danger btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Não</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de sucesso
                    isOpen={this.state.sucesso}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label>{this.state.mensagemSucesso}</label><br />

                        <div className="row">
                            <div className="col">
                                <Link to="/Home/Empresa/Tabela">
                                    <button onClick={this.ModalSucesso} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de erro
                    isOpen={this.state.erro}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center modalErro">
                        <label> Ops! Temos problemas: </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p> {this.state.tipoErro} </p>}
                        <div className="row">
                            <div className="col">
                                <button onClick={this.ModalErro} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de erro refresh
                    isOpen={this.state.refresh}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center modalErro">
                        <label> Ops! Temos problemas: </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p> {this.state.tipoErro} </p>}
                        <div className="row">
                            <div className="col">
                                <button onClick={this.ModalErro} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de Loading
                    isOpen={this.state.loading}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label> Carregando dados... </label><br />
                        <Spinner color="primary" size="lg" />
                    </div>
                </Modal>

                <div className="col-12 col-sm-11 col-md-9 col-lg-10 col-xl-8">
                    <div>
                        <div className="h4 text-center">
                            Empresa
                        </div>

                        <form onSubmit={this.OnClickEnvia}>
                            <div className="form-row">
                                <div className="form-group col">
                                    <label htmlFor="nomeFantasia">Nome Fantasia:</label>
                                    <input
                                        className={this.state.nomeFantasia_erro ? 'form-control is-invalid' : 'form-control'}
                                        type="text"
                                        id="nomeFantasia"
                                        name="nomeFantasia"
                                        value={this.state.nomeFantasia}
                                        onChange={this.atribuiNomeFantasia}
                                        onBlur={() => this.validaNomeFantasia(null)}
                                        maxLength="100"
                                        required="required"
                                        autoComplete="off"
                                    />
                                    <div className="invalid-feedback">{this.state.nomeFantasia_erro}</div>
                                </div>

                                <div className="form-group col">
                                    <label htmlFor="atividadeEconomica">Atividade Econômica:</label>
                                    <input
                                        className={this.state.atividade_erro ? 'form-control is-invalid' : 'form-control'}
                                        type="text"
                                        id="atividadeEconomica"
                                        name="atividadeEconomica"
                                        value={this.state.atividadeEconomica}
                                        onChange={this.atribuiAtividadeEconomica}
                                        onBlur={() =>this.validaAtividadeEconomica(null)}
                                        maxLength="100"
                                        required="required"
                                        autoComplete="off"
                                    />
                                    <div className="invalid-feedback">{this.state.atividade_erro}</div>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col">
                                    <label htmlFor="CPF_CNPJ" >CPF/CNPJ:</label>
                                    <input
                                        className={this.state.CPF_CNPJ_erro ? 'form-control is-invalid' : 'form-control'}
                                        type="text"
                                        id="CPF_CNPJ"
                                        name="CPF_CNPJ"
                                        value={this.state.CPF_CNPJ}
                                        onChange={this.atribuiCPF_CNPJ}
                                        onBlur={() => this.validaCPF_CNPJ(null)}
                                        // onBlur={this.state.CPF_CNPJ !== '' ? this.validaCPF_CNPJ : null}
                                        maxLength="14"
                                        required="required"
                                        autoComplete="off"
                                    />
                                    <div className="invalid-feedback"> {this.state.CPF_CNPJ_erro}</div>
                                </div>

                                <div className="form-group col">
                                    <label htmlFor="razaoSocial">Razão Social:</label>
                                    <input
                                        type="text"
                                        id="razaoSocial"
                                        name="razaoSocial"
                                        value={this.state.razaoSocial}
                                        onChange={this.atribuiRazaoSocial}
                                        onBlur={() => this.validaRazaoSocial(null)}
                                        className={this.state.razaoSocial_erro ? 'form-control is-invalid' : 'form-control'}
                                        maxLength="40"
                                        required="required"
                                        autoComplete="off"
                                    />
                                    <div className="invalid-feedback"> {this.state.razaoSocial_erro}</div>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col">
                                    <label htmlFor="endereco">Endereço</label>
                                    <textarea
                                        className={this.state.endereco_erro ? "form-control is-invalid tamanhoTextarea" : "form-control tamanhoTextarea"}
                                        id="endereco"
                                        rows="3"
                                        cols="5"
                                        value={this.state.endereco}
                                        onChange={this.atribuiEndereco}
                                        onBlur={() => this.validaEndereco(null)}
                                        required="required"
                                    >
                                    </textarea>
                                    <div className="invalid-feedback"> {this.state.endereco_erro}</div>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col text-right">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-sm col-7 col-sm-6 col-md-5 col-lg-4 col-xl-4">
                                        Salvar
                                    </button>
                                </div>
                                <div className="form-group col text-left">
                                    <button
                                        type="button"
                                        onClick={this.ModalCancelamento}
                                        className="btn btn-danger btn-sm col-7 col-sm-6 col-md-5 col-lg-4 col-xl-4">
                                        Cancelar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProp = (state) => ({
    options: state.dadosEmpresa.options
});

const mapActionstoProps = {
    changeEmpresas
}

export default connect(mapStateToProp, mapActionstoProps)(Formulario);