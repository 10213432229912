import React from "react";
import { Switch, Route } from 'react-router-dom';

import TabelaUser from './tabela_User';
import FormularioUser from './form_user';

const RotasUser = () => (
    <Switch>
        <Route path='/Home/User/Tabela' component={TabelaUser} />
        <Route path='/Home/User/NovoUser' component={FormularioUser} />
    </Switch>
);

export default RotasUser;