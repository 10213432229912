import React, { Component } from "react";
import { Link } from 'react-router-dom'
import Modal from 'react-modal';
import axios from 'axios';
import Cookies from 'universal-cookie'
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';

import { MdNavigateBefore, MdNavigateNext, MdFirstPage, MdLastPage } from 'react-icons/md';
import { IoIosClose } from "react-icons/io";
// import { FaRegImage, FaSistrix, FaFilePdf, FaMapMarkedAlt } from "react-icons/fa";
import { FaRegImage, FaSistrix, FaMapMarkedAlt } from "react-icons/fa";
import { FiFilter } from "react-icons/fi";

import paginate from 'paginate-array';
import ReactTooltip from 'react-tooltip'
// import Select from 'react-select';
// import CheckBox from 'react-simple-checkbox';

Modal.setAppElement('#root');

// const FileDownload = require('js-file-download');

const Requisicoes = Object.freeze({
    POST: 'POST',
    GET: 'GET'
});

const anteontem = () => {
    let result = new Date();
    result.setDate(result.getDate() - 2);
    //ALterado: Jorge
    // result.setDate(result.getDate() - 2);
    // let dia = result.getDate();
    // dia = ('0' + dia).slice(-2);
    // let mes = result.getMonth() + 1;
    // mes = ('0' + mes).slice(-2);

    return formataDate(result)
    //return result.getFullYear() + "-" + mes + "-" + dia;
}

function formataDate(data) {

    let dia = data.getDate();
    dia = ('0' + dia).slice(-2);
    let mes = data.getMonth() + 1;
    mes = ('0' + mes).slice(-2);
    let ano = data.getFullYear();

    return ano + "-" + mes + "-" + dia;
}

const hoje = () => {
    let result = new Date();
    //ALterado: Jorge
    // let dia = result.getDate();
    // dia = ('0' + dia).slice(-2);
    // let mes = result.getMonth() + 1;
    // mes = ('0' + mes).slice(-2);

    return formataDate(result)

    //return result.getFullYear() + "-" + mes + "-" + dia;
}

const estiloModalDetalhes = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'transparent',
        borderColor: 'transparent'
    }
};

class TabelaMarcacao extends Component {
    constructor(props) {
        super(props);

        this.state = {
            erro: false,
            cancela: false,
            refresh: false,
            loading: false,
            modalDetalhes: false,

            mensagemSucesso: '',
            tipoErro: [],
            mensagemModal: 'Ops! Temos problemas:',
            redirect: false,
            marcacao: [],
            marcacaoFiltradas: [],
            size: 7,
            page: 1,
            currPage: null,

            filtro: '',
            tipoPesquisa: '',
            DataInicial: anteontem(),
            DataFinal: hoje(),
            //modalPDF: false,
            // IDUsuarios: '',
            // usuarios: { value: '', label: '' },
            // IDUsuarios_value: [],
            // todos: true,
            resultado: '',
            imagem: ''
        }
        // this.enviaDados = this.enviaDados.bind(this);

        this.ModalErro = this.ModalErro.bind(this);
        this.ModalCancelamento = this.ModalCancelamento.bind(this);
        this.ModalRefresh = this.ModalRefresh.bind(this);

        this.requisicaoRefresh = this.requisicaoRefresh.bind(this);
        // this.OnClickEnvia = this.OnClickEnvia.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.lastPage = this.lastPage.bind(this);
        this.firstPage = this.firstPage.bind(this);
        this.buttonPaginacao = this.buttonPaginacao.bind(this);

        this.mostraAtualizacao = this.mostraAtualizacao.bind(this);

        this.atribuiPesquisa = this.atribuiPesquisa.bind(this);
        this.atribuiTipoPesquisa = this.atribuiTipoPesquisa.bind(this);
        this.filtro = this.filtro.bind(this);
        this.limpaPesquisa = this.limpaPesquisa.bind(this);

        this.requisicaoListagem = this.requisicaoListagem.bind(this);
        this.atribuiDataInicial = this.atribuiDataInicial.bind(this);
        this.atribuiDataFinal = this.atribuiDataFinal.bind(this);
        this.ajustaData = this.ajustaData.bind(this);
        this.onClickListagem = this.onClickListagem.bind(this);

        //this.abreModalPDF = this.abreModalPDF.bind(this);
        // this.requisicaoUsuarios = this.requisicaoUsuarios.bind(this);

        // this.atribuiTodos = this.atribuiTodos.bind(this);
        // this.atribuiUsuarios = this.atribuiUsuarios.bind(this);

        this.lerErros = this.lerErros.bind(this);
        this.lerImagem = this.lerImagem.bind(this);
    }

    atribuiTodos(check) {
        this.setState({ todos: check })
    }

    //TODO: Vai ser removido
    // requisicaoUsuarios() {
    //     let cookie = new Cookies();
    //     let token = cookie.get('token');

    //     axios.get(process.env.REACT_APP_URL_REQUISICOES + this.props.empresaSelecionada.value + '/colaboradores',
    //         {

    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': 'Bearer ' + token
    //             }
    //         })
    //         .then(res => {
    //             if (res.status === 200) {
    //                 const usuarios = res.data;
    //                 let optionsUsuarios = [];

    //                 usuarios.map(usuario => {
    //                     optionsUsuarios.push(
    //                         { value: usuario.IDColaborador, label: usuario.Nome }
    //                     )
    //                     return null
    //                 })

    //                 this.setState({
    //                     usuarios: optionsUsuarios
    //                 });
    //             }
    //         })
    //         .catch((error) => {
    //             if (error.response !== undefined || error.response === '') {
    //                 switch (error.response.status) {
    //                     case 400:
    //                         this.setState({
    //                             tipoErro: error.response.data,
    //                             loading: false
    //                         });
    //                         this.ModalErro();
    //                         break;

    //                     case 401:
    //                         this.requisicaoRefresh();
    //                         break;

    //                     case 403:
    //                         this.setState({
    //                             tipoErro: "Ação não autorizada",
    //                             loading: false
    //                         });
    //                         this.ModalErro();
    //                         break;

    //                     case 408:
    //                         this.setState({
    //                             tipoErro: 'Não foi possível conectar ao servidor',
    //                             loading: false
    //                         });
    //                         this.ModalErro();
    //                         break;

    //                     default:
    //                         this.setState({
    //                             tipoErro: "Erro não identificado. Contate o administrador!",
    //                             loading: false
    //                         });
    //                         this.ModalRefresh();
    //                         break;
    //                 }
    //             }
    //             else {
    //                 this.setState({
    //                     tipoErro: "Erro não identificado. Contate o administrador!",
    //                     loading: false
    //                 });
    //                 this.ModalRefresh();
    //             }
    //         })
    // }

    //Removido
    // atribuiUsuarios(IDUsuarios) {
    //     let IDUsuarios_value = [];

    //     if (IDUsuarios) {
    //         IDUsuarios.map(usuario => {
    //             IDUsuarios_value.push(
    //                 usuario.value
    //             )
    //             return null
    //         })

    //         this.setState({
    //             IDUsuarios,
    //             IDUsuarios_value: IDUsuarios_value
    //         });
    //     }
    // }

    //Vai ser removido
    // abreModalPDF() {
    //     if (this.props.empresaSelecionada === '') {
    //         this.setState({ tipoErro: "Selecione uma empresa", mensagemModal: 'Atenção!' })
    //         this.ModalErro();
    //     }
    //     else {
    //         this.setState({ modalPDF: !this.state.modalPDF })
    //     }
    // }

    ajustaData(dataJson) {
        let data = new Date(dataJson);
        //ALterado: Jorge
        let dia = data.getDate();
        dia = ('0' + dia).slice(-2);
        let mes = data.getMonth() + 1;
        mes = ('0' + mes).slice(-2);
        let ano = data.getFullYear();

        let dataString = dia + '/' + mes + '/' + ano;
        return dataString;
    }

    atribuiDataInicial(event) {
        this.setState({ DataInicial: event.target.value })
    }

    atribuiDataFinal(event) {
        this.setState({ DataFinal: event.target.value })
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (this.props.empresaSelecionada.value === undefined) {
            this.setState({
                tipoErro: 'Selecione uma Empresa',
                mensagemModal: 'Atenção!'
            });
            this.ModalErro();
        }
        else {
            this.requisicaoListagem();
            // this.requisicaoUsuarios();
        }
    }

    requisicaoListagem() {
        this.setState({ loading: true });

        let cookie = new Cookies();
        let token = cookie.get('token');

        //axios.post('https://192.168.1.62:5002/api/v1/' + this.props.empresaSelecionada.value + '/marcacoes',
        axios.post(process.env.REACT_APP_URL_REQUISICOES_V2 + this.props.empresaSelecionada.value + '/marcacoes',
            {
                DataInicial: this.state.DataInicial,
                DataFinal: this.state.DataFinal
            },
            {

                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                const marcacao = res.data;
                let { page, size } = this.state;

                const currPage = paginate(marcacao, page, size);

                this.setState({
                    marcacao,
                    currPage,
                    marcacaoFiltradas: marcacao,
                    loading: false,
                });
            })
            .catch((error) => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 401:
                            this.requisicaoRefresh(Requisicoes.POST);
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 408:
                            this.setState({
                                tipoErro: 'Não foi possível conectar ao servidor',
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            })
    }

    onClickListagem(event) {
        event.preventDefault();

        if (this.props.empresaSelecionada === '') {
            this.setState({ tipoErro: "Selecione uma empresa", mensagemModal: 'Atenção!' })
            this.ModalErro();
        }
        else {
            this.requisicaoListagem();
        }
    }

    atribuiTipoPesquisa(event) {
        let tipoPesquisa = event.target.value;
        this.setState({ tipoPesquisa });
    }

    atribuiPesquisa(event) {
        let filtro = event.target.value;
        this.setState({ filtro });
    }

    limpaPesquisa() {
        this.setState({ filtro: '' });
    }

    filtro(event) {
        event.preventDefault();

        let { tipoPesquisa } = this.state;
        let { filtro } = this.state;
        let marcacaoFiltradas = [];

        if (filtro !== '') {
            switch (tipoPesquisa) {
                case 'Matricula':
                    marcacaoFiltradas = this.state.marcacao.filter(marcacao => {
                        return marcacao.Matricula === filtro;
                    });
                    break;

                case 'Nome':
                    marcacaoFiltradas = this.state.marcacao.filter(marcacao => {
                        return marcacao.Nome.toLowerCase().includes(filtro.toLowerCase());
                    });
                    break;

                case 'Data':
                    marcacaoFiltradas = this.state.marcacao.filter(marcacao => {
                        return (marcacao.DataMarcacao.split('T')[0]) === filtro;
                    });
                    break;

                default:
                    this.setState({ marcacaoFiltradas: this.state.marcacao });
                    break;
            }
        }
        else {
            marcacaoFiltradas = this.state.marcacao;
        }

        this.setState({ marcacaoFiltradas });
        this.mostraAtualizacao(marcacaoFiltradas);
    }

    mostraAtualizacao(marcacaoFiltradas) {
        const size = this.state.size;

        const currPage = paginate(marcacaoFiltradas, 1, size);

        this.setState({ currPage, page: 1 });
    }

    firstPage() {
        const { size, marcacaoFiltradas } = this.state;

        const newPage = 1;
        const newCurrPage = paginate(marcacaoFiltradas, newPage, size);

        this.setState({
            page: newPage,
            currPage: newCurrPage
        });
    }

    lastPage() {
        const { size, marcacaoFiltradas } = this.state;

        if (this.state.currPage !== null) {
            const newPage = this.state.currPage.totalPages > 0 ? this.state.currPage.totalPages : 1;
            const newCurrPage = paginate(marcacaoFiltradas, newPage, size);

            this.setState({
                page: newPage,
                currPage: newCurrPage
            });
        }
    }

    previousPage() {
        const { page, size, marcacaoFiltradas } = this.state;

        if (page > 1) {
            const newPage = page - 1;
            const newCurrPage = paginate(marcacaoFiltradas, newPage, size);

            this.setState({
                page: newPage,
                currPage: newCurrPage
            });
        }
    }

    nextPage() {
        const { currPage, page, size, marcacaoFiltradas } = this.state;

        if (this.state.currPage !== null) {
            if (page < currPage.totalPages) {
                let newPage = page + 1;
                let newCurrPage = paginate(marcacaoFiltradas, newPage, size);
                this.setState({
                    page: newPage,
                    currPage: newCurrPage
                });
            }
        }
    }

    buttonPaginacao(currPage) {
        if (currPage !== null) {
            if (currPage.totalPages === 1 || currPage.totalPages === 0) {
                return (
                    <button className="btn btn-outline-primary active">{this.state.page}</button>
                );
            }
            else if (currPage.currentPage === currPage.totalPages) {
                return (
                    <div className="btn-group">
                        <button className="btn btn-outline-primary" onClick={this.previousPage}>{this.state.page - 1}</button>
                        <button className="btn btn-outline-primary active">{this.state.page}</button>
                    </div>
                );
            }
            else if (this.state.page - 1 === 0) {
                return (
                    <div className="btn-group">
                        <button className="btn btn-outline-primary active">{this.state.page}</button>
                        <button className="btn btn-outline-primary" onClick={this.nextPage}>{this.state.page + 1}</button>
                    </div>
                );
            }
            else {
                return (
                    <div className="btn-group">
                        <button className="btn btn-outline-primary" onClick={this.previousPage}>{this.state.page - 1}</button>
                        <button className="btn btn-outline-primary active">{this.state.page}</button>
                        <button className="btn btn-outline-primary" onClick={this.nextPage}>{this.state.page + 1}</button>
                    </div>
                );
            }
        }
    }

    //Vai ser removido
    // OnClickEnvia(event) {
    //     event.preventDefault();
    //     if (this.props.empresaSelecionada.value === undefined) {
    //         this.setState({
    //             tipoErro: 'Selecione uma Empresa',
    //             mensagemModal: 'Atenção!'
    //         });
    //         this.ModalErro();
    //     }
    //     else {
    //         this.setState({
    //             loading: true
    //         });
    //         this.enviaDados();
    //     }
    // }

    lerErros(blob) {
        return new Promise(function (resolve, reject) { //cria uma promise com uma arrow function
            let fileReader = new FileReader();
            let resultado = "";

            fileReader.onload = () => {
                resultado = fileReader.result;
                return resolve(resultado);
            };

            fileReader.readAsText(blob);
        }
        );
    }

    lerImagem(imagens) {
        return new Promise(function (resolve, reject) {
            const reader = new FileReader();

            reader.onloadend = () => {
                return resolve(reader.result);
            };

            reader.readAsDataURL(imagens);
        }
        );
    }

    //TODO: Essa função vai ser removida daqui
    // enviaDados() {
    //     let cookie = new Cookies();
    //     let token = cookie.get('token');

    //     axios.post(process.env.REACT_APP_URL_REQUISICOES + this.props.empresaSelecionada.value + '/relatorios/listagens',
    //         {
    //             DataInicial: this.state.DataInicial,
    //             DataFinal: this.state.DataFinal,
    //             IDs: this.state.todos === true ? [] : this.state.IDUsuarios_value
    //         },
    //         {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': 'Bearer ' + token
    //             },
    //             responseType: "blob"
    //         })
    //         .then(res => {
    //             let dataInicio = this.state.DataInicial.split("-");
    //             dataInicio = (dataInicio[2] + dataInicio[1] + dataInicio[0]);

    //             let dataFim = this.state.DataFinal.split("-");
    //             dataFim = (dataFim[2] + dataFim[1] + dataFim[0]);

    //             FileDownload(res.data, 'Marcacoes_' + dataInicio + '_' + dataFim + '.pdf');
    //             this.setState({ loading: false })

    //         })
    //         .catch(async (error) => {
    //             if (error.response !== undefined || error.response === '') {
    //                 //atribui a resposta da função lerErros para a varial resultado
    //                 let resultado = await this.lerErros(error.response.data);

    //                 let arrayResultado = [];
    //                 arrayResultado = resultado.split(';');

    //                 switch (error.response.status) {
    //                     case 400:
    //                         this.setState({
    //                             tipoErro: arrayResultado,
    //                             loading: false
    //                         });
    //                         this.ModalErro();
    //                         break;

    //                     case 401:
    //                         this.requisicaoRefresh();
    //                         break;

    //                     case 403:
    //                         this.setState({
    //                             tipoErro: "Ação não autorizada",
    //                             loading: false
    //                         });
    //                         this.ModalErro();
    //                         break;

    //                     case 408:
    //                         this.setState({
    //                             tipoErro: 'Não foi possível conectar ao servidor',
    //                             loading: false
    //                         });
    //                         this.ModalErro();
    //                         break;

    //                     default:
    //                         this.setState({
    //                             tipoErro: "Erro não identificado. Contate o administrador!",
    //                             loading: false
    //                         });
    //                         this.ModalRefresh();
    //                         break;
    //                 }
    //             }
    //             else {
    //                 this.setState({
    //                     tipoErro: "Erro não identificado. Contate o administrador!",
    //                     loading: false
    //                 });
    //                 this.ModalRefresh();
    //             }
    //         })
    // }

    requisicaoRefresh(tipoRequisicao) {
        let cookie = new Cookies();
        let token = cookie.get('token')
        let refreshToken = cookie.get('refreshToken')

        axios.post(process.env.REACT_APP_URL_REQUISICOES + 'auth/refresh',
            {
                refreshToken: refreshToken
            },
            {

                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                if (res.status === 200) {
                    const cookies = new Cookies();

                    cookies.set('token', res.data.token, { path: '/' });
                    cookies.set('refreshToken', res.data.refreshToken, { path: '/' });

                    switch (tipoRequisicao) {
                        case Requisicoes.POST:
                            this.requisicaoListagem();
                            break;
                        case Requisicoes.GET:
                            this.exibeDetalhes();
                            break;

                        default:
                            break;
                    }
                }
            })
            .catch((error) => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        case 401:
                            this.setState({
                                tipoErro: 'Sessão Inválida',
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        case 408:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            })
    }

    ModalRefresh() {
        this.setState({ refresh: !this.state.refresh })
    }

    ModalErro() {
        this.setState({ erro: !this.state.erro })
    }

    ModalCancelamento() {
        this.setState({ cancela: !this.state.cancela })
    }

    exibeDetalhes(IDMarcacao) {
        this.setState({ loading: true });

        let cookie = new Cookies();
        let token = cookie.get('token');

        console.log(this.props.empresaSelecionada.value)
        axios.get(process.env.REACT_APP_URL_REQUISICOES_V2 + this.props.empresaSelecionada.value + '/marcacoes/imagens/' + IDMarcacao,
            {
                headers: {
                    'Content-Type': 'image/jpeg',
                    'Authorization': 'Bearer ' + token
                },
                responseType: "blob"
            })
            .then(async res => {
                let resultado = await this.lerImagem(res.data);

                this.setState({
                    modalDetalhes: true,
                    loading: false,
                    imagem: resultado
                })
            })
            .catch(async (error) => {
                if (error.response !== undefined || error.response === '') {
                    let resultado = await this.lerErros(error.response.data);

                    let arrayResultado = [];
                    arrayResultado = resultado.split(';');

                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: arrayResultado,
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 401:
                            this.requisicaoRefresh(Requisicoes.GET);
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 408:
                            this.setState({
                                tipoErro: 'Não foi possível conectar ao servidor',
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            })
    }

    verificaLocalizacao(Latitude, Longitude) {
        if (Latitude === '' || Longitude === '') {
            return <td>
                <button className="btn" data-tip="Ver localização" disabled>
                    <FaMapMarkedAlt size={20} value={{ color: "black" }} />
                </button>
                <ReactTooltip place="top" type="dark" effect="solid" />
            </td>
        }
        else {
            return <td>
                <button className="btn" data-tip="Ver localização">
                    <a href={"https://www.google.com/maps/search/?api=1&query=" + Latitude + ',' + Longitude} target="_blank" rel="noopener noreferrer" className="tiraEstiloLink">
                        <FaMapMarkedAlt size={20} />
                    </a>
                </button>
                <ReactTooltip place="top" type="dark" effect="solid" />
            </td>
        }
    }

    verificaImagem(possuiFoto, IDMarcacao) {
        if (possuiFoto === true) {
            return <td className="align-middle">
                <button className="btn" data-tip="Ver Imagem" onClick={() => this.exibeDetalhes(IDMarcacao)}><FaRegImage size={20} /></button>
                <ReactTooltip place="top" type="dark" effect="solid" />
            </td>
        }
        else {
            return <td className="align-middle">
                <button className="btn" data-tip="Ver Imagem" disabled ><FaRegImage size={20} /></button>
                <ReactTooltip place="top" type="dark" effect="solid" />
            </td>
        }

    }

    formataNome(nome){
        var nomeArray = nome.split(' ');
        
        if(nomeArray.length > 2){
            return nomeArray.slice(0, -1).join(' ');
        }
        else{
            return nome;
        }
    }

    render() {
        let tipoInput = this.state.tipoPesquisa === "Data" ? "date" : "text";
        const { currPage } = this.state;
        return (
            <div className="h-100 margemTop">
                {/* <Modal //Modal PDF
                    isOpen={this.state.modalPDF}
                    className="modal-pdf col-10 col-md-8"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label>Selecione os colaboradores:</label>
                        <form onSubmit={this.OnClickEnvia}>
                            <div className="form-row">
                                <div className="form-group col">
                                    <Select
                                        value={this.state.todos === true ? '' : this.state.IDUsuarios}
                                        onChange={this.atribuiUsuarios}
                                        options={this.state.usuarios}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        isDisabled={this.state.todos === true ? true : false}
                                    />
                                </div>
                                <div className="form-group col-4 col-sm-3 col-md-3 col-lg-2">
                                    <label htmlFor="todos" className="labelCheckbox">Todos</label>
                                    <CheckBox
                                        id='todos'
                                        size={4}
                                        tickSize={3}
                                        borderThickness={1}
                                        checked={this.state.todos}
                                        onChange={this.atribuiTodos}
                                        color={{ backgroundColor: '#007bef', borderColor: '#007bef', uncheckedBorderColor: '#ced4da' }}
                                        tickAnimationDuration={100}
                                        backAnimationDuration={100} />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col text-right">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-sm col-7 col-sm-6 col-md-5 col-lg-4 col-xl-4">
                                        Baixar
                                    </button>
                                </div>
                                <div className="form-group col text-left">
                                    <button
                                        type="button"
                                        onClick={this.abreModalPDF}
                                        className="btn btn-danger btn-sm col-7 col-sm-6 col-md-5 col-lg-4 col-xl-4">
                                        Cancelar
                                        </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal> */}
                <Modal //Modal de exclusão
                    isOpen={this.state.exclusao}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label> Certeza que deseja excluir esse colaborador? </label>
                        <div className="row">
                            <div className="col text-right">
                                <button onClick={this.requisicaoDelete} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Sim</button>
                            </div>

                            <div className="col text-left">
                                <button onClick={this.ModalDelete} className="btn btn-outline-danger btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Não</button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal  //Modal de erro
                    isOpen={this.state.erro}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center modalErro">
                        <label> {this.state.mensagemModal} </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p> {this.state.tipoErro} </p>}
                        <div className="row">
                            <div className="col">
                                <button onClick={this.ModalErro} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal  //Modal de Loading
                    isOpen={this.state.loading}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label> Carregando dados... </label><br />
                        <Spinner color="primary" size="lg" />
                    </div>
                </Modal>
                <Modal  //Modal de refresh
                    isOpen={this.state.refresh}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center modalErro">
                        <label> Ops! Temos problemas: </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p> {this.state.tipoErro} </p>}
                        <div className="row">
                            <div className="col">
                                <Link to='/'><button onClick={this.ModalRefresh} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button></Link>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal  //Modal de Loading
                    isOpen={this.state.loading}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label> Carregando dados... </label><br />
                        <Spinner color="primary" size="lg" />
                    </div>
                </Modal>
                <Modal  //Modal de detalhes
                    isOpen={this.state.modalDetalhes}
                    style={estiloModalDetalhes}
                    overlayClassName="overlay"
                >
                    <div className="card">
                        <div className="card-header">
                            <div className="float-right">
                                <button
                                    type="button"
                                    className="btn btn-secundary"
                                    onClick={() => this.setState({ modalDetalhes: false })}>
                                    <IoIosClose size={30} />
                                </button>
                            </div>
                        </div>
                        <div className="card-body text-center">
                            <div className="col">
                                <img src={this.state.imagem} alt="Foto marcação de Ponto" className="img-responsive col-12 col-sm-11 col-md-9 col-lg-10 col-xl-8" />
                            </div>
                        </div>
                    </div>
                </Modal>
                <nav className="margemBottom">
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-11 col-md-9 col-lg-10 col-xl-8">
                            <div className="form-row margemBottom">
                                <div className="col-12 col-sm-3 col-md-6 col-lg-3">
                                    <label htmlFor="DataInicial">Data Inicial:</label>
                                    <input
                                        className='form-control'
                                        type="date"
                                        id="DataInicial"
                                        name="DataInicial"
                                        value={this.state.DataInicial}
                                        onChange={this.atribuiDataInicial}
                                    />
                                </div>
                                <div className="col-12 col-sm-3 col-md-6 col-lg-3">
                                    <label htmlFor="DataFinal">Data Final:</label>
                                    <div className="input-group-prepend">
                                        <div className="input-group">
                                            <input
                                                className='form-control'
                                                type="date"
                                                id="DataFinal"
                                                name="DataFinal"
                                                value={this.state.DataFinal}
                                                onChange={this.atribuiDataFinal}
                                            />
                                            <button type="button" className="input-group-text" onClick={this.onClickListagem}><FiFilter /></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-3 col-md-6 col-lg-3">
                                    <label> &nbsp;&nbsp; </label>
                                    <select className="form-control" name="tipoPesquisa" required="required" onBlur={this.atribuiTipoPesquisa} onChange={this.limpaPesquisa}>
                                        <option defaultValue> Selecione </option>
                                        <option value="Matricula"> Matricula </option>
                                        <option value="Nome"> Nome </option>
                                        <option value="Data"> Data </option>
                                    </select>
                                </div>

                                <div className="col-12 col-sm-3 col-md-6 col-lg-3">
                                    <label> &nbsp;&nbsp; </label>
                                    <div className="input-group-prepend">
                                        <div className="input-group">
                                            <input placeholder="Pesquisar" type={tipoInput} className="form-control" required="required" autoComplete="off" value={this.state.filtro} onChange={this.atribuiPesquisa} />
                                            <button type="submit" className="input-group-text" onClick={this.filtro}><FaSistrix /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>

                <div className="row justify-content-center h-100 expansaoDiv">
                    <div className="col-12 col-sm-11 col-md-9 col-lg-10 col-xl-8">
                        <div className="table-responsive">
                            <table className="table table-hover table-bordeless table-sm text-center">
                                <thead>
                                    <tr className="table table-primary">
                                        <th scope="col" className="align-middle">Matricula</th>
                                        <th scope="col" className="align-middle">Nome</th>
                                        <th scope="col" className="align-middle">Data</th>
                                        <th scope="col" className="align-middle">Hora</th>
                                        <th scope="col" className="align-middle" colSpan="2">Detalhes</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {currPage &&
                                        currPage.data.map((marcacoes, index) =>
                                            <tr key={index}>
                                                <td className="align-middle">{marcacoes.Matricula}</td>
                                                <td className="align-middle">{this.formataNome(marcacoes.Nome)}</td>
                                                <td className="align-middle">{this.ajustaData(marcacoes.DataMarcacao)}</td>
                                                <td className="align-middle">{marcacoes.HoraMarcacao.substring(0, 5)}</td>
                                                {this.verificaImagem(marcacoes.PossuiFoto, marcacoes.IDMarcacao)}
                                                {this.verificaLocalizacao(marcacoes.Latitude, marcacoes.Longitude)}
                                            </tr>
                                        )}
                                </tbody>
                            </table>
                        </div>
                        <div className="row justify-content-end right paginacao">
                            <div className="btn-toolbar">
                                <div className="btn-group">
                                    <button className="btn btn-outline-primary" onClick={this.firstPage}><MdFirstPage /></button>
                                    <button className="btn btn-outline-primary" onClick={this.previousPage}><MdNavigateBefore /></button>

                                    {this.buttonPaginacao(currPage)}

                                    <button className="btn btn-outline-primary" onClick={this.nextPage}><MdNavigateNext /></button>
                                    <button className="btn btn-outline-primary" onClick={this.lastPage}><MdLastPage /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row justify-content-center">
                        <div className="col-1">
                            <button type="button" className="btn btn-primary" data-tip="Baixar PDF" onClick={this.abreModalPDF}><FaFilePdf /></button>
                            <ReactTooltip place="top" type="dark" effect="solid" />
                        </div>
                    </div> */}
                </div>
            </div >
        );
    }
}

const mapStateToProp = (state) => ({
    empresaSelecionada: state.dadosEmpresa.empresaSelecionada
});

export default connect(mapStateToProp)(TabelaMarcacao);