import React, { Component } from "react";

import BotaoForm from './botao_paraForm';
import Cookies from 'universal-cookie';
import axios from 'axios';
import paginate from 'paginate-array';
import Modal from 'react-modal';
import { Spinner } from 'reactstrap';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { changeReset, changeIDEmpresa, changeEmpresas } from '../Home/actions/actionsIDEmpresa';

import { FaSistrix } from 'react-icons/fa';
import { MdNavigateBefore, MdNavigateNext, MdFirstPage, MdLastPage } from 'react-icons/md';
import { GoPencil, GoTrashcan } from "react-icons/go";

const ROLE_Admin = "Admin";
const ROLE_RH = "RH";
const ROLE_User = "User";

class TabelaColaborador extends Component {
    constructor(props) {
        super(props);

        this.state = {
            colaboradores: [],
            colaboradoresFiltrados: [],
            pesquisa: '',
            tipoPesquisa: '',
            size: 7,
            page: 1,
            currPage: null,
            tipoErro: [],
            mensagemModal: 'Ops! Temos problemas:',
            redirect: false,
            nome: '',
            PIS: '',
            CPF: '', //Adicionei. Jorge
            numeroCarteira: '', //Adicionei. Jorge
            serieCarteira: '', //Adicionei. Jorge
            matricula: '', //Adicionei. Jorge
            cargo: '', //Adicionei. Jorge
            UserName: '',
            alterasenha: '',
            localizacao: '',
            foto: '',
            IDColaborador: '',

            loading: false,
            exclusao: false,
            erro: false,
            refresh: false
        }
        this.requisicaoListagem = this.requisicaoListagem.bind(this);
        this.mostraAtualizacao = this.mostraAtualizacao.bind(this);
        this.atribuiTipoPesquisa = this.atribuiTipoPesquisa.bind(this);
        this.atribuiPesquisa = this.atribuiPesquisa.bind(this);
        this.filtro = this.filtro.bind(this);
        
        this.firstPage = this.firstPage.bind(this);
        this.lastPage = this.lastPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.buttonPaginacao = this.buttonPaginacao.bind(this);

        this.requisicaoRefresh = this.requisicaoRefresh.bind(this);

        this.PontuacaoPIS = this.PontuacaoPIS.bind(this);
        this.requisicaoConsulta = this.requisicaoConsulta.bind(this);
        this.requisicaoListagem = this.requisicaoListagem.bind(this);

        this.requisicaoDelete = this.requisicaoDelete.bind(this);
        this.requisicaoAtualizacao = this.requisicaoAtualizacao.bind(this);
        this.ModalDelete = this.ModalDelete.bind(this);
        this.ModalLoading = this.ModalLoading.bind(this);
        this.ModalErro = this.ModalErro.bind(this);
        this.ModalRefresh = this.ModalRefresh.bind(this);
        this.verificaRole = this.verificaRole.bind(this);
    }

    verificaRole() {
        switch (this.props.role) {
            case ROLE_Admin:
                return true;

            case ROLE_RH:
                return true;

            case ROLE_User:
                return false;

            default:
                return false;
        }
    }

    ModalLoading() {
        this.setState({ loading: !this.state.loading })
    }

    ModalErro() {
        this.setState({ erro: !this.state.erro })
    }

    ModalRefresh() {
        this.setState({ refresh: !this.state.refresh })
    }

    ModalDelete(IDColaborador) {
        this.setState({ IDColaborador, exclusao: !this.state.exclusao });
    }

    requisicaoDelete() {
        this.setState({ loading: true });
        let cookie = new Cookies();
        let token = cookie.get('token');

        axios.delete(process.env.REACT_APP_URL_REQUISICOES + this.props.empresaSelecionada.value + '/colaboradores/' + this.state.IDColaborador,
            {

                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                this.setState({ exclusao: false, loading: false });
            });
        this.requisicaoAtualizacao();
    }

    requisicaoAtualizacao() {
        let colaboradores = this.state.colaboradores;
        let IDColaborador = this.state.IDColaborador;

        let posicao = colaboradores.findIndex(colaborador => colaborador.IDColaborador === IDColaborador);
        colaboradores.splice(posicao, 1);

        let { page, size } = this.state;

        let currPage = paginate(colaboradores, page, size);

        if (currPage.totalPages < page) {
            page = currPage.totalPages;
            currPage = paginate(colaboradores, page, size);
        }

        this.setState({
            colaboradores,
            page,
            currPage,
            colaboradoresFiltrados: colaboradores
        });
    }

    requisicaoConsulta(IDColaborador) {
        this.setState({ loading: true });

        let cookie = new Cookies();
        let token = cookie.get('token');
        let colaborador;

        axios.get(process.env.REACT_APP_URL_REQUISICOES + this.props.empresaSelecionada.value + '/colaboradores/' + IDColaborador,
            {

                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                colaborador = res.data;
                this.setState({
                    IDColaborador: IDColaborador,
                    nome: colaborador.colaborador.Nome,
                    PIS: colaborador.colaborador.PIS.substr(1, 12),
                    UserName: colaborador.UserName,
                    alterasenha: colaborador.AlteraSenha,
                    CPF: colaborador.colaborador.CPF,
                    numeroCarteira: colaborador.colaborador.CTPS,
                    serieCarteira: colaborador.colaborador.Serie,
                    matricula: colaborador.colaborador.Matricula,
                    cargo: colaborador.colaborador.Cargo === null ? '' : colaborador.colaborador.Cargo,
                    localizacao: colaborador.colaborador.LocalizacaoMobile,
                    foto: colaborador.colaborador.FotoMobile,
                    redirect: true,
                    loading: false
                })
            })
            .catch((error) => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 401:
                            this.requisicaoRefresh();
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalErro();
                }
            })
    }

    PontuacaoPIS(PIS) {
        let PISPontuacao = PIS.replace(/^([\d]{3})([\d]{5})([\d]{2})([\d]{1})$/, "$1.$2.$3-$4");

        return PISPontuacao
    }

    componentDidMount() {
        // Faz com que volte ao inicio da página
        window.scrollTo(0, 0);

        if (this.props.empresaSelecionada === '') {
            this.setState({ tipoErro: "Selecione uma empresa", mensagemModal: 'Atenção!' })
            this.ModalErro();
        }
        else {
            // Inicia a listagem  de colaboradores cadastradas
            this.requisicaoListagem();
        }

    }

    requisicaoListagem() {
        this.setState({ loading: true });

        let cookie = new Cookies();
        let token = cookie.get('token');

        axios.get(process.env.REACT_APP_URL_REQUISICOES + this.props.empresaSelecionada.value + '/colaboradores',
            {

                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                const colaboradores = res.data;
                let { page, size } = this.state;

                const currPage = paginate(colaboradores, page, size);

                this.setState({
                    colaboradores,
                    currPage,
                    colaboradoresFiltrados: colaboradores,
                    loading: false
                });
            })
            .catch((error) => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 401:
                            this.requisicaoRefresh();
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 408:
                            this.setState({
                                tipoErro: 'Não foi possível conectar ao servidor',
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            })
    }

    requisicaoRefresh() {
        let cookie = new Cookies();
        let token = cookie.get('token')
        let refreshToken = cookie.get('refreshToken')

        axios.post(process.env.REACT_APP_URL_REQUISICOES + 'auth/refresh',
            {
                refreshToken: refreshToken
            },
            {

                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                if (res.status === 200) {
                    const cookies = new Cookies();

                    cookies.set('token', res.data.token, { path: '/' });
                    cookies.set('refreshToken', res.data.refreshToken, { path: '/' });
                    this.requisicaoListagem();
                }
            })
            .catch((error) => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        case 401:
                            this.setState({
                                tipoErro: 'Sessão Inválida',
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            })
    }

    atribuiTipoPesquisa(event) {
        // Atribui o tipo de pesquisa que será usada no filtro
        let tipoPesquisa = event.target.value;
        this.setState({ tipoPesquisa, pesquisa: '' });
    }

    atribuiPesquisa(event) {
        // Atribui a pesquisa que será usada no filtro
        let pesquisa = event.target.value;
        this.setState({ pesquisa });
    }

    filtro(event) {
        //Faz a filtragem em sí
        event.preventDefault();
        let { tipoPesquisa } = this.state;
        let { pesquisa } = this.state;
        let colaboradoresFiltrados = [];

        if (tipoPesquisa === "Nome") {
            if (pesquisa !== '') {
                colaboradoresFiltrados = this.state.colaboradores.filter(colaboradores => {
                    return colaboradores.Nome.toLowerCase().includes(pesquisa.toLowerCase());
                });
            }
            else {
                colaboradoresFiltrados = this.state.colaboradores;
            }
        }
        else if (tipoPesquisa === "PIS") {
            colaboradoresFiltrados = this.state.colaboradores.filter(colaboradores => {
                return colaboradores.PIS.includes(pesquisa);
            });
        }
        else {
            colaboradoresFiltrados = this.state.colaboradores;
        }

        this.setState({ colaboradoresFiltrados });
        this.mostraAtualizacao(colaboradoresFiltrados);
    }

    mostraAtualizacao(colaboradoresFiltrados) {
        //Atualiza as colaboradores que aparecem após a filtragem
        const size = this.state.size;

        const currPage = paginate(colaboradoresFiltrados, 1, size);

        this.setState({ currPage, page: 1 });
    }

    firstPage() {
        //Determina a próxima página
        const { size, colaboradoresFiltrados } = this.state;

        const newPage = 1;
        const newCurrPage = paginate(colaboradoresFiltrados, newPage, size);

        this.setState({
            page: newPage,
            currPage: newCurrPage
        });
    }

    lastPage() {
        //Determina a página anterior
        const { size, colaboradoresFiltrados } = this.state;

        if (this.state.currPage !== null) {
            const newPage = this.state.currPage.totalPages > 0 ? this.state.currPage.totalPages : 1;
            const newCurrPage = paginate(colaboradoresFiltrados, newPage, size);
            this.setState({
                page: newPage,
                currPage: newCurrPage
            });
        }
    }

    previousPage() {
        const { page, size, colaboradoresFiltrados } = this.state;

        if (page > 1) {
            const newPage = page - 1;
            const newCurrPage = paginate(colaboradoresFiltrados, newPage, size);

            this.setState({
                page: newPage,
                currPage: newCurrPage
            });
        }
    }

    nextPage() {
        const { currPage, page, size, colaboradoresFiltrados } = this.state;

        if (this.state.currPage !== null) {
            if (page < currPage.totalPages) {
                let newPage = page + 1;
                let newCurrPage = paginate(colaboradoresFiltrados, newPage, size);
                this.setState({
                    page: newPage,
                    currPage: newCurrPage
                });
            }
        }
    }

    buttonPaginacao(currPage) {
        if (currPage !== null) {
            if (currPage.totalPages === 1 || currPage.totalPages === 0) {
                return (
                    <button className="btn btn-outline-primary active">{this.state.page}</button>
                );
            }
            else if (currPage.currentPage === currPage.totalPages) {
                return (
                    <div className="btn-group">
                        <button className="btn btn-outline-primary" onClick={this.previousPage}>{this.state.page - 1}</button>
                        <button className="btn btn-outline-primary active">{this.state.page}</button>
                    </div>
                );
            }
            else if (this.state.page - 1 === 0) {
                return (
                    <div className="btn-group">
                        <button className="btn btn-outline-primary active">{this.state.page}</button>
                        <button className="btn btn-outline-primary" onClick={this.nextPage}>{this.state.page + 1}</button>
                    </div>
                );
            }
            else {
                return (
                    <div className="btn-group">
                        <button className="btn btn-outline-primary" onClick={this.previousPage}>{this.state.page - 1}</button>
                        <button className="btn btn-outline-primary active">{this.state.page}</button>
                        <button className="btn btn-outline-primary" onClick={this.nextPage}>{this.state.page + 1}</button>
                    </div>
                );
            }
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={{
                pathname: '/Home/Colaborador/NovoColaborador/Formulario',
                state: {
                    IDColaborador: this.state.IDColaborador,
                    nome: this.state.nome,
                    PIS: this.state.PIS,
                    CPF: this.state.CPF,
                    UserName: this.state.UserName,
                    alterasenha: this.state.alterasenha,
                    localizacao: this.state.localizacao,
                    foto: this.state.foto,
                    serieCarteira: this.state.serieCarteira,
                    numeroCarteira: this.state.numeroCarteira,
                    cargo: this.state.cargo,
                    matricula: this.state.matricula
                }
            }} />
        }
        const { currPage } = this.state;
        return (
            <div className="h-100 margemTop">
                <Modal //Modal de exclusão
                    isOpen={this.state.exclusao}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label> Certeza que deseja excluir esse colaborador? </label>
                        <div className="row">
                            <div className="col text-right">
                                <button onClick={this.requisicaoDelete} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Sim</button>
                            </div>

                            <div className="col text-left">
                                <button onClick={this.ModalDelete} className="btn btn-outline-danger btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Não</button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal  //Modal de erro
                    isOpen={this.state.erro}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center modalErro">
                        <label> {this.state.mensagemModal} </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p> {this.state.tipoErro} </p>}
                        <div className="row">
                            <div className="col">
                                <button onClick={this.ModalErro} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal  //Modal de Loading
                    isOpen={this.state.loading}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label> Carregando dados... </label><br />
                        <Spinner color="primary" size="lg" />
                    </div>
                </Modal>
                <Modal  //Modal de refresh
                    isOpen={this.state.refresh}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center modalErro">
                        <label> Ops! Temos problemas: </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p> {this.state.tipoErro} </p>}
                        <div className="row">
                            <div className="col">
                                <Link to='/'><button onClick={this.ModalRefresh} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button></Link>
                            </div>
                        </div>
                    </div>
                </Modal>
                <nav className="margemBottom">
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-11 col-md-9 col-lg-10 col-xl-8">
                            <div className="row">
                                <div className="col">
                                    <span className="navbar-brand"><label> Colaboradores </label></span>
                                </div>
                                <form>
                                    <div className="row">
                                        <div className="col-4 col-sm-5 col-md-4 col-lg-4">
                                            <select
                                                className="form-control input-group-prepend"
                                                type="select"
                                                name="tipopesquisa"
                                                required
                                                onChange={this.atribuiTipoPesquisa}>
                                                <option defaultValue> Selecione </option>
                                                <option value="Nome" className="input-group-text"> Nome </option>
                                                <option value="PIS" className="input-group-text"> PIS </option>
                                            </select>
                                        </div>
                                        <div className="col-8 col-sm-7 col-md-8 col-lg-8 tiraPadding">
                                            <div className="input-group-prepend">
                                                <div className="input-group">
                                                    <input id="filtro"
                                                        placeholder="Pesquisar"
                                                        type="text"
                                                        value={this.state.pesquisa}
                                                        className="form-control col-10"
                                                        onChange={this.atribuiPesquisa}
                                                        autoComplete="off" />
                                                    <button type="submit" className="input-group-text" onClick={this.filtro}><FaSistrix /></button>
                                                </div>
                                                {this.verificaRole()
                                                    ?
                                                    <div className="col text-right tiraPadding">
                                                        <BotaoForm />
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </nav>

                <div className="row justify-content-center h-100 expansaoDiv">
                    <div className="col-12 col-sm-11 col-md-9 col-lg-10 col-xl-8">
                        <div className="table-responsive">
                            <table className="table table-hover table-bordeless table-sm text-center">
                                <thead>
                                    <tr className="table table-primary">
                                        <th className="align-middle">Nome</th>
                                        <th className="align-middle">PIS </th>
                                        <th scope="col" colSpan="2">  </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currPage &&
                                        currPage.data.map(colaboradores =>
                                            <tr key={colaboradores.IDColaborador}>

                                                <td className="align-middle">{colaboradores.Nome}</td>
                                                <td className="align-middle">{this.PontuacaoPIS(colaboradores.PIS)}</td>
                                                {this.verificaRole()
                                                    ?
                                                    <td className="align-middle">
                                                        <button className="btn" data-tip="Editar" onClick={() => this.requisicaoConsulta(colaboradores.IDColaborador)}><GoPencil size={20} /></button>
                                                    </td>
                                                    :
                                                    null
                                                }
                                                {this.verificaRole()
                                                    ?
                                                    <td className="align-middle">
                                                        <button className="btn" data-tip="Excluir" onClick={() => this.ModalDelete(colaboradores.IDColaborador)}><GoTrashcan size={20} /></button>
                                                    </td>
                                                    :
                                                    null
                                                }
                                            </tr>
                                        )}
                                </tbody>
                            </table>
                        </div>
                        <div className="row justify-content-end right paginacao">
                            <div className="btn-toolbar">
                                <div className="btn-group">
                                    <button className="btn btn-outline-primary" onClick={this.firstPage}><MdFirstPage /></button>
                                    <button className="btn btn-outline-primary" onClick={this.previousPage}><MdNavigateBefore /></button>

                                    {this.buttonPaginacao(currPage)}

                                    <button className="btn btn-outline-primary" onClick={this.nextPage}><MdNavigateNext /></button>
                                    <button className="btn btn-outline-primary" onClick={this.lastPage}><MdLastPage /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProp = (state) => ({
    empresaSelecionada: state.dadosEmpresa.empresaSelecionada,
    role: state.dadosEmpresa.role
});
const mapActionstoProps = {
    changeReset,
    changeIDEmpresa,
    changeEmpresas
}
export default connect(mapStateToProp, mapActionstoProps)(TabelaColaborador);

