import React, { Component } from 'react';
import RotasPermissao from './rotasPermissoes';
import NavbarSistema from '../Home/navbarSistema';


export default class Permissoes extends Component {
    render() {
        return (
            <div>
                <NavbarSistema />
                <div className="h-100">
                    <div className="col-12 col-sm-11 col-md-9 col-lg-10 col-xl-8 my-auto">
                        <RotasPermissao />
                    </div>
                </div>
            </div>
        );
    }
}