import React, { Component } from "react";
import { Link, Redirect } from 'react-router-dom'
import Modal from 'react-modal';
import axios from 'axios';
import Cookies from 'universal-cookie';
import Select from 'react-select';
import { Spinner } from 'reactstrap';
import { connect } from 'react-redux';

Modal.setAppElement('#root');

const Requisicoes = Object.freeze({
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
});

const options = [
    { value: 'Admin', label: 'Admin' },
    { value: 'User', label: 'User' },
    { value: 'RH', label: 'RH' },
];

class FormularioUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            UserName: this.props.location.state.UserName,
            UserName_erro: '',

            senha: '',
            senha_erro: '',

            Roles: this.props.location.state.Roles,

            IDUsuario: this.props.location.state.IDUsuario,

            sucesso: false,
            erro: false,
            cancela: false,
            refresh: false,

            mensagemSucesso: '',
            tipoErro: [],
            mensagemModal: 'Ops! Temos problemas:',
            redirect: false,

            token: '',
            refreshToken: '',
            loading: false
        }

        this.atribuiUserName = this.atribuiUserName.bind(this);
        this.validaUserName = this.validaUserName.bind(this);

        this.atribuiSenha = this.atribuiSenha.bind(this);
        this.validaSenha = this.validaSenha.bind(this);

        this.atribuiRoles = this.atribuiRoles.bind(this);

        this.ModalSucesso = this.ModalSucesso.bind(this);
        this.ModalErro = this.ModalErro.bind(this);
        this.ModalCancelamento = this.ModalCancelamento.bind(this);
        this.ModalRefresh = this.ModalRefresh.bind(this);

        this.OnClickEnvia = this.OnClickEnvia.bind(this);
        this.requisicaoRefresh = this.requisicaoRefresh.bind(this);
        this.ModalLoading = this.ModalLoading.bind(this);
    }

    componentDidMount() {
        if (this.props.empresaSelecionada.value === undefined) {
            this.setState({ tipoErro: "Selecione uma empresa", mensagemModal: 'Atenção!' })
            this.ModalErro();
        }
    }

    atribuiUserName(event) {
        let UserName = event.target.value;

        this.setState({ UserName })

        this.validaUserName(UserName)
    }

    validaUserName(userNameParam) {
        let UserName = this.state.UserName;
        let UserName_erro = ''

        if(userNameParam){
            UserName = userNameParam
        }

        if(UserName.length < 4)
        {
            UserName_erro = 'Username precisa ter mais de 3 caracteres'
        }

        this.setState({ UserName_erro })
    }

    atribuiSenha(event) {
        this.setState({ senha: event.target.value })
    }

    validaSenha() {
        let senha = this.state.senha;
        let senha_erro = '';

        if (!senha.match(/^[a-z0-9]+$/i) && senha !== '') {
            senha_erro = 'A senha deve conter apenas letras e números';
        }
        else {
            senha_erro = '';
        }

        this.setState({ senha_erro })
    }

    atribuiRoles(Roles) {
        this.setState({ Roles });
    }

    OnClickEnvia(event) {
        event.preventDefault();
        this.setState({ loading: true })

        if (this.state.senha_erro === '' && this.state.UserName_erro === '') {
            if (this.props.empresaSelecionada.value !== undefined) {
                if (this.state.IDUsuario === '') {
                    this.requisicaoEnvio();
                }
                else {
                    this.requisicaoAlteracao();
                }
            }
            else {
                this.setState({
                    tipoErro: "Selecione uma empresa",
                    mensagemModal: 'Atenção!',
                    loading: false
                });
                this.ModalErro();
            }
        }
        else{
            this.setState({
                tipoErro: "Campos inválidos",
                mensagemModal: 'Atenção!',
                loading: false
            });
            this.ModalErro();
        }
    }

    requisicaoAlteracao() {
        let cookie = new Cookies();
        let token = cookie.get('token');

        // axios.put('https://192.168.1.62:5002/api/v1/' + 'usuarios/' + this.state.IDUsuario,
        axios.put(process.env.REACT_APP_URL_REQUISICOES + 'usuarios/' + this.state.IDUsuario,
            {
                IDUsuario: this.state.IDUsuario,
                UserName: this.state.UserName,
                Senha: this.state.senha,
                Role: this.state.Roles.value
            },
            {

                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                if (res.status === 201) {
                    this.ModalSucesso();

                    this.setState({
                        UserName: '',
                        senha: '',
                        foto: false,
                        localizacao: false,
                        mensagemSucesso: 'Alteração feita com sucesso!',
                        loading: false
                    })
                }
            })
            .catch((error) => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 401:
                            this.requisicaoRefresh(Requisicoes.PUT);
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 408:
                            this.setState({
                                tipoErro: 'Não foi possível conectar ao servidor',
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            })
    }

    requisicaoEnvio() {
        let cookie = new Cookies();
        let token = cookie.get('token');

        // axios.post(process.env.REACT_APP_URL_BASENAME + 'usuarios',
        axios.post(process.env.REACT_APP_URL_REQUISICOES + 'usuarios',
            {
                UserName: this.state.UserName,
                Senha: this.state.senha,
                Role: this.state.Roles.value
            },
            {

                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                if (res.status === 201) {
                    this.ModalSucesso();

                    this.setState({
                        UserName: '',
                        senha: '',
                        foto: false,
                        localizacao: false,
                        mensagemSucesso: 'Cadastro feito com sucesso!',
                        loading: false
                    })
                }
            })
            .catch((error) => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 401:
                            this.requisicaoRefresh(Requisicoes.POST);
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 408:
                            this.setState({
                                tipoErro: 'Não foi possível conectar ao servidor',
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            })
    }

    requisicaoRefresh(tipoRequisicao) {
        let cookie = new Cookies();
        let token = cookie.get('token')
        let refreshToken = cookie.get('refreshToken')

        axios.post(process.env.REACT_APP_URL_REQUISICOES + 'auth/refresh',
            {
                refreshToken: refreshToken
            },
            {

                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                if (res.status === 200) {
                    const cookies = new Cookies();

                    cookies.set('token', res.data.token, { path: '/' });
                    cookies.set('refreshToken', res.data.refreshToken, { path: '/' });

                    switch (tipoRequisicao) {
                        case Requisicoes.POST:
                            this.requisicaoEnvio();
                            break;
                        case Requisicoes.PUT:
                            this.requisicaoAlteracao();
                            break;
                        default:
                            break;
                    }
                }
            })
            .catch(error => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        case 401:
                            this.setState({
                                tipoErro: 'Sessão Inválida',
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            })
    }

    ModalRefresh() {
        this.setState({ modalOpenRefresh: true })
    }

    ModalSucesso() {
        this.setState({ sucesso: !this.state.sucesso })
    }

    ModalErro() {
        this.setState({ erro: !this.state.erro })
    }

    ModalCancelamento() {
        this.setState({ cancela: !this.state.cancela })
    }

    ModalLoading() {
        this.setState({ loading: !this.state.loading })
    }

    render() {
        let redirecionamento = this.state.redirect ? <Redirect to="/Home/User/Tabela" /> : null;
        return (
            <div className="row justify-content-center h-100 margemTop expansaoDiv">
                <Modal //modal de cancelamento
                    isOpen={this.state.cancela}
                    //onRequestClose={this.ModalCancelamento}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label> Suas informações serão perdidas. Deseja continuar? </label>
                        <div className="row">
                            <div className="col text-right">
                                <Link to='/Home/User/Tabela'><button className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Sim</button></Link>
                            </div>

                            <div className="col text-left">
                                <button onClick={this.ModalCancelamento} className="btn btn-outline-danger btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Não</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de sucesso
                    isOpen={this.state.sucesso}
                    //onRequestClose={this.ModalSucesso}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label>{this.state.mensagemSucesso}</label><br />

                        <div className="row">
                            <div className="col">
                                <Link to="/Home/User/Tabela"><button onClick={this.ModalSucesso} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button></Link>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de erro
                    isOpen={this.state.erro}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center modalErro">
                        <label> {this.state.mensagemModal} </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p> {this.state.tipoErro} </p>}
                        <div className="row">
                            <div className="col">
                                <button onClick={this.ModalErro} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de erro refresh
                    isOpen={this.state.modalOpenRefresh}
                    //onRequestClose={this.ModalRefresh}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center modalErro">
                        <label> Ops! Temos problemas: </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p> {this.state.tipoErro} </p>}
                        <div className="row">
                            <div className="col">
                                <Link to="/"><button onClick={this.ModalErro} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button></Link>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de Loading
                    isOpen={this.state.loading}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label> Carregando dados... </label><br />
                        <Spinner color="primary" size="lg" />
                    </div>
                </Modal>
                <div className="col-12 col-sm-11 col-md-9 col-lg-10 col-xl-8">
                    <div>
                        <div className="h4 text-center">
                            Usuario
                        </div>

                        <form onSubmit={this.OnClickEnvia}>
                            <div className="form-row">
                                <div className="form-group col-12 col-sm-6">
                                    <label htmlFor="UserName">UserName:</label>
                                    <input
                                        className={this.state.UserName_erro ? 'form-control is-invalid' : 'form-control'}
                                        type="text"
                                        id="UserName"
                                        name="UserName"
                                        value={this.state.UserName}
                                        onChange={this.atribuiUserName}
                                        onBlur={() =>this.validaUserName(null)}
                                        maxLength="14"
                                        required="required"
                                        autoComplete="off"
                                    />
                                    <div className="invalid-feedback"> {this.state.UserName_erro}</div>
                                </div>

                                <div className="form-group col-12 col-sm-6">
                                    <label htmlFor="senha">Senha:</label>
                                    <input
                                        type="password"
                                        className={this.state.senha_erro ? 'form-control is-invalid' : 'form-control'}
                                        id="senha"
                                        value={this.state.senha}
                                        onChange={this.atribuiSenha}
                                        onBlur={this.validaSenha}
                                        maxLength="40"
                                        autoComplete="off"
                                    />
                                    <div className="invalid-feedback"> {this.state.senha_erro}</div>

                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-12 col-md-6">
                                    <label htmlFor='Roles'>Permissão</label>
                                    <Select
                                        value={this.state.Roles}
                                        onChange={this.atribuiRoles}
                                        options={options}
                                    />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col text-right">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-sm col-7 col-sm-6 col-md-5 col-lg-4 col-xl-4">
                                        Salvar
                                    </button>
                                </div>
                                <div className="form-group col text-left">
                                    <button
                                        type="button"
                                        onClick={this.ModalCancelamento}
                                        className="btn btn-danger btn-sm col-7 col-sm-6 col-md-5 col-lg-4 col-xl-4">
                                        Cancelar
                                        </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {redirecionamento}
            </div>
        );
    }
}

const mapStateToProp = (state) => ({
    empresaSelecionada: state.dadosEmpresa.empresaSelecionada
});

export default connect(mapStateToProp)(FormularioUser);