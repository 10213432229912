import React, { Component } from "react";
import { Link } from 'react-router-dom'
import Modal from 'react-modal';
import axios from 'axios';
import Cookies from 'universal-cookie'
import { Redirect } from "react-router/cjs/react-router.min";
import { connect } from 'react-redux';
import Select from 'react-select';
import CheckBox from 'react-simple-checkbox';
import { Spinner } from 'reactstrap';

Modal.setAppElement('#root');

const FileDownload = require('js-file-download');

const Requisicoes = Object.freeze({
    COLABORADOR: 'COLABORADOR',
    AFD: 'AFD',
    ESPELHO: 'ESPELHO',
    LISTAGEM: 'LISTAGEM',
    DESLIGADO: 'DESLIGADO',
    ESPELHODESLIGADO: 'ESPELHODESLIGADO'
});

const options = [
    { value: 'AFD', label: "AFD" },
    { value: 'ESPELHO', label: "Espelho de Marcação" },
    { value: 'LISTAGEM', label: "Listagem de Marcação" },
    { value: 'DESLIGADO', label: "Desligados" }
]

class FormularioRelatorio extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataInicio: '',
            dataFim: '',

            erro: false,
            cancela: false,
            refresh: false,
            loading: false,

            mensagemSucesso: '',
            tipoErro: [],
            mensagemModal: 'Ops! Temos problemas:',
            redirect: false,
            tipoRelatorio: [],
            IDUsuarios: '',
            listaUsuarios: { value: '', label: '' },
            IDUsuariosSelecionados: [],
            todos: true,
            IDUsuariosDesligados: '',
            listaUsuariosDesligados: {value: '', label: ''},
            IDUsuariosDesligadosSelecionados: []
        }

        this.atribuidataInicio = this.atribuidataInicio.bind(this);
        this.atribuidataFim = this.atribuidataFim.bind(this);
        this.atribuiTipoRelatorio = this.atribuiTipoRelatorio.bind(this);
        this.atribuiUsuarios = this.atribuiUsuarios.bind(this);
        this.atribuiUsuariosDesligados = this.atribuiUsuariosDesligados.bind(this);

        this.enviaDados = this.enviaDados.bind(this);

        this.ModalErro = this.ModalErro.bind(this);
        this.ModalCancelamento = this.ModalCancelamento.bind(this);
        this.ModalRefresh = this.ModalRefresh.bind(this);

        this.apagaDados = this.apagaDados.bind(this);

        this.requisicaoRefresh = this.requisicaoRefresh.bind(this);
        this.OnClickEnvia = this.OnClickEnvia.bind(this);
        this.requisicaoUsuarios = this.requisicaoUsuarios.bind(this);
        this.requisicaoUsuariosDesligados = this.requisicaoUsuariosDesligados.bind(this);

        this.atribuiTodos = this.atribuiTodos.bind(this);
        this.relatorioListagem = this.relatorioListagem.bind(this);
    }

    componentDidMount() {
        if (this.props.empresaSelecionada.value === undefined) {
            this.setState({
                tipoErro: 'Selecione uma Empresa',
                mensagemModal: 'Atenção!',
            });
            this.ModalErro();
        }
        else {
            this.requisicaoUsuarios();
            this.requisicaoUsuariosDesligados();
        }
    }

    atribuiTodos(check) {
        this.setState({ todos: check })
    }

    requisicaoUsuarios() {
        let cookie = new Cookies();
        let token = cookie.get('token');

        axios.get(process.env.REACT_APP_URL_REQUISICOES + this.props.empresaSelecionada.value + '/colaboradores',
            {

                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                if (res.status === 200) {
                    const usuarios = res.data;
                    let listaUsuarios = [];

                    usuarios.map(usuario => {
                        listaUsuarios.push(
                            { value: usuario.IDColaborador, label: usuario.Nome }
                        )
                        return null
                    })

                    this.setState({
                        listaUsuarios
                    });
                }
            })
            .catch((error) => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 401:
                            this.requisicaoRefresh(Requisicoes.COLABORADOR);
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 408:
                            this.setState({
                                tipoErro: 'Não foi possível conectar ao servidor',
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            })
    }

    requisicaoUsuariosDesligados() {
        let cookie = new Cookies();
        let token = cookie.get('token');

        axios.get(process.env.REACT_APP_URL_REQUISICOES + this.props.empresaSelecionada.value + '/colaboradores/desligados',
            {

                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                if (res.status === 200) {
                    const usuariosDesligados = res.data;
                    let listaUsuariosDesligados = [];

                    usuariosDesligados.map(usuario => {
                        listaUsuariosDesligados.push(
                            { value: usuario.IDColaborador, label: usuario.Nome }
                        )
                        return null
                    })

                    this.setState({
                        listaUsuariosDesligados
                    });
                }
            })
            .catch((error) => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 401:
                            this.requisicaoRefresh(Requisicoes.DESLIGADO);
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 408:
                            this.setState({
                                tipoErro: 'Não foi possível conectar ao servidor',
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            })
    }

    atribuidataInicio(event) {
        this.setState({ dataInicio: event.target.value })
    }

    atribuidataFim(event) {
        this.setState({ dataFim: event.target.value })
    }

    atribuiTipoRelatorio(tipoRelatorio) {
        this.setState({ tipoRelatorio })
    }

    atribuiUsuarios(IDUsuarios) {
        let IDUsuariosSelecionados = [];

        if (IDUsuarios) {
            IDUsuarios.map(usuario => {
                IDUsuariosSelecionados.push(
                    usuario.value
                )
                return null
            })

            this.setState({
                IDUsuarios,
                IDUsuariosSelecionados: IDUsuariosSelecionados
            });
        }
    }

    atribuiUsuariosDesligados(IDUsuariosDesligados) {
        let IDUsuariosDesligadosSelecionados = [];

        if (IDUsuariosDesligados) {
            IDUsuariosDesligados.map(usuario => {
                IDUsuariosDesligadosSelecionados.push(
                    usuario.value
                )
                return null
            })

            this.setState({
                IDUsuariosDesligados,
                IDUsuariosDesligadosSelecionados: IDUsuariosDesligadosSelecionados
            });
        }
    }

    OnClickEnvia(event) {
        event.preventDefault();

        this.setState({ loading: true })
        this.enviaDados();
    }

    enviaDados() {
        let tipoRelatorio = this.state.tipoRelatorio.value;
        
        if (this.props.empresaSelecionada === '') {
            this.setState({ tipoErro: "Selecione uma empresa", mensagemModal: 'Atenção!', loading: false })
            this.ModalErro();
        }
        else if(this.state.todos === false && this.state.IDUsuariosSelecionados.length === 0){
            this.setState({ tipoErro: "Selecione um Colaborador", mensagemModal: 'Atenção!', loading: false })
            this.ModalErro();
        }
        else {
            switch (tipoRelatorio) {
                case Requisicoes.AFD:
                    this.relatorioAFD();
                    break;
                case Requisicoes.ESPELHO:
                    this.relatorioEspelho();
                    break;
                case Requisicoes.LISTAGEM:
                    this.relatorioListagem();
                    break;
                case Requisicoes.COLABORADOR:
                    this.requisicaoUsuarios();
                    break;
                case Requisicoes.DESLIGADO:
                case Requisicoes.ESPELHODESLIGADO:
                    this.relatorioEspelhoDesligados();
                    break;
                default:
                    this.setState({
                        tipoErro: "Selecione um tipo de relatório",
                        loading: false
                    });
                    this.ModalRefresh();
                    break;
            }
        }

    }

    lerErros(blob) {
        return new Promise(function (resolve, reject) { //cria uma promise com uma arrow function
            let fileReader = new FileReader();
            let resultado = "";

            fileReader.onload = () => {
                resultado = fileReader.result;
                return resolve(resultado);
            };

            fileReader.readAsText(blob);
        }
        );
    }

    relatorioListagem() {
        let cookie = new Cookies();
        let token = cookie.get('token');

        axios.post(process.env.REACT_APP_URL_REQUISICOES + this.props.empresaSelecionada.value + '/relatorios/listagens',
            {
                DataInicial: this.state.dataInicio,
                DataFinal: this.state.dataFim,
                IDs: this.state.todos === true ? [] : this.state.IDUsuariosSelecionados
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                responseType: "blob"
            })
            .then(res => {

                FileDownload(res.data, 'ListagemMarcacao.pdf');
                this.setState({ loading: false })

            })
            .catch(async (error) => {
                if (error.response !== undefined || error.response === '') {
                    //atribui a resposta da função lerErros para a varial resultado
                    let resultado = await this.lerErros(error.response.data);

                    let arrayResultado = [];
                    arrayResultado = resultado.split(';');

                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: arrayResultado,
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 401:
                            this.requisicaoRefresh(Requisicoes.LISTAGEM);
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 408:
                            this.setState({
                                tipoErro: 'Não foi possível conectar ao servidor',
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            })
    }

    relatorioEspelho() {
        let cookie = new Cookies();
        let token = cookie.get('token');

        axios.post(process.env.REACT_APP_URL_REQUISICOES + this.props.empresaSelecionada.value + '/relatorios/espelhos',
            // axios.post('https://192.168.1.62:5002/api/v1/' + this.props.empresaSelecionada.value + '/relatorios/espelhos',
            {
                DataInicial: this.state.dataInicio,
                DataFinal: this.state.dataFim,
                IDs: this.state.todos === true ? [] : this.state.IDUsuariosSelecionados
            },
            {

                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                responseType: "blob", //Para baixar PDF é necessário o uso dessa linha
            })
            .then(res => {
                FileDownload(res.data, 'EspelhoMarcacao.pdf');
                this.setState({ loading: false })
            })
            .catch(async (error) => {
                if (error.response !== undefined || error.response === '') {
                    //atribui a resposta da função lerErros para a varial resultado
                    let resultado = await this.lerErros(error.response.data);

                    let arrayResultado = [];
                    arrayResultado = resultado.split(';');

                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: arrayResultado,
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 401:
                            this.requisicaoRefresh(Requisicoes.ESPELHO);
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 408:
                            this.setState({
                                tipoErro: 'Não foi possível conectar ao servidor',
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            })
    }

    relatorioEspelhoDesligados() {
        let cookie = new Cookies();
        let token = cookie.get('token');

        axios.post(process.env.REACT_APP_URL_REQUISICOES + this.props.empresaSelecionada.value + '/relatorios/espelhos/desligados',
            // axios.post('https://192.168.1.62:5002/api/v1/' + this.props.empresaSelecionada.value + '/relatorios/espelhos',
            {
                DataInicial: this.state.dataInicio,
                DataFinal: this.state.dataFim,
                IDs: this.state.todos === true ? [] : this.state.IDUsuariosDesligadosSelecionados
            },
            {

                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                responseType: "blob", //Para baixar PDF é necessário o uso dessa linha
            })
            .then(res => {
                FileDownload(res.data, 'EspelhoMarcacaoDesligado.pdf');
                this.setState({ loading: false })
            })
            .catch(async (error) => {
                if (error.response !== undefined || error.response === '') {
                    //atribui a resposta da função lerErros para a varial resultado
                    let resultado = await this.lerErros(error.response.data);

                    let arrayResultado = [];
                    arrayResultado = resultado.split(';');

                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: arrayResultado,
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 401:
                            this.requisicaoRefresh(Requisicoes.ESPELHODESLIGADO);
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 408:
                            this.setState({
                                tipoErro: 'Não foi possível conectar ao servidor',
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            })
    }

    relatorioAFD() {
        let cookie = new Cookies();
        let token = cookie.get('token');

        axios.post(process.env.REACT_APP_URL_REQUISICOES + this.props.empresaSelecionada.value + '/relatorios/afds',
            {
                DataInicial: this.state.dataInicio,
                DataFinal: this.state.dataFim
            },
            {

                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                let dataInicio = this.state.dataInicio.split("-");
                dataInicio = (dataInicio[2] + dataInicio[1] + dataInicio[0]);

                let dataFim = this.state.dataFim.split("-");
                dataFim = (dataFim[2] + dataFim[1] + dataFim[0]);

                this.setState({ loading: false })
                FileDownload(res.data, 'AFD_' + dataInicio + '_' + dataFim + '.txt');
            })
            .catch((error) => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 401:
                            this.requisicaoRefresh(Requisicoes.AFD);
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        case 408:
                            this.setState({
                                tipoErro: 'Não foi possível conectar ao servidor',
                                loading: false
                            });
                            this.ModalErro();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            })
    }

    requisicaoRefresh(tipoRequisicao) {
        let cookie = new Cookies();
        let token = cookie.get('token')
        let refreshToken = cookie.get('refreshToken')

        axios.post(process.env.REACT_APP_URL_REQUISICOES + 'auth/refresh',
            {
                refreshToken: refreshToken
            },
            {

                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                if (res.status === 200) {
                    const cookies = new Cookies();

                    cookies.set('token', res.data.token, { path: '/' });
                    cookies.set('refreshToken', res.data.refreshToken, { path: '/' });

                    if (tipoRequisicao === Requisicoes.COLABORADOR) {
                        this.requisicaoUsuarios();
                    }
                    else if (tipoRequisicao === Requisicoes.DESLIGADO){
                        this.requisicaoUsuariosDesligados();
                    }
                    else {
                        this.enviaDados(tipoRequisicao);
                    }
                }
            })
            .catch((error) => {
                if (error.response !== undefined || error.response === '') {
                    switch (error.response.status) {
                        case 400:
                            this.setState({
                                tipoErro: error.response.data,
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        case 401:
                            this.setState({
                                tipoErro: 'Sessão Inválida',
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        case 403:
                            this.setState({
                                tipoErro: "Ação não autorizada",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;

                        default:
                            this.setState({
                                tipoErro: "Erro não identificado. Contate o administrador!",
                                loading: false
                            });
                            this.ModalRefresh();
                            break;
                    }
                }
                else {
                    this.setState({
                        tipoErro: "Erro não identificado. Contate o administrador!",
                        loading: false
                    });
                    this.ModalRefresh();
                }
            })
    }

    ModalRefresh() {
        this.setState({ refresh: !this.state.refresh })
    }

    ModalErro() {
        this.setState({ erro: !this.state.erro })
    }

    ModalCancelamento() {
        this.setState({ cancela: !this.state.cancela })
    }

    apagaDados() {
        this.setState({
            cancela: false,
            dataInicio: '',
            dataFim: '',
            tipoRelatorio: '',
        })
    }

    render() {
        let redirecionamento = this.state.redirect ? <Redirect to="/Home/Relatorio/" /> : null;
        let selectUsuarios = this.state.tipoRelatorio.value === Requisicoes.ESPELHO || this.state.tipoRelatorio.value === Requisicoes.LISTAGEM ?
            <div className="form-row">
                <div className="form-group col">
                    <Select
                        value={this.state.todos === true ? "" : this.state.IDUsuarios}
                        onChange={this.atribuiUsuarios}
                        options={this.state.listaUsuarios}
                        isMulti
                        closeMenuOnSelect={false}
                        isDisabled={this.state.todos}
                    />
                </div>
                <div className="form-group col-4 col-sm-3 col-md-3 col-lg-2">
                    <label htmlFor="todos" className="labelCheckbox">Todos</label>
                    <CheckBox
                        id='todos'
                        size={4}
                        tickSize={3}
                        borderThickness={1}
                        checked={this.state.todos}
                        onChange={this.atribuiTodos}
                        color={{ backgroundColor: '#007bef', borderColor: '#007bef', uncheckedBorderColor: '#ced4da' }}
                        tickAnimationDuration={100}
                        backAnimationDuration={100} />
                </div>
            </div>
            : null;

        let selectUsuariosDesligados = this.state.tipoRelatorio.value === Requisicoes.DESLIGADO ?
            <div className="form-row">
                <div className="form-group col">
                    <Select
                        value={this.state.todos === true ? "" : this.state.IDUsuariosDesligados}
                        onChange={this.atribuiUsuariosDesligados}
                        options={this.state.listaUsuariosDesligados}
                        isMulti
                        closeMenuOnSelect={false}
                        isDisabled={this.state.todos === true ? true : false}
                    />
                </div>
                <div className="form-group col-4 col-sm-3 col-md-3 col-lg-2">
                    <label htmlFor="todos" className="labelCheckbox">Todos</label>
                    <CheckBox
                        id='todos'
                        size={4}
                        tickSize={3}
                        borderThickness={1}
                        checked={this.state.todos}
                        onChange={this.atribuiTodos}
                        color={{ backgroundColor: '#007bef', borderColor: '#007bef', uncheckedBorderColor: '#ced4da' }}
                        tickAnimationDuration={100}
                        backAnimationDuration={100} />
                </div>
            </div>
            : null;

        return (
            <div className="row justify-content-center h-100 margemTop">
                <Modal //modal de cancelamento
                    isOpen={this.state.cancela}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label> Suas informações serão perdidas. Deseja continuar? </label>
                        <div className="row">
                            <div className="col text-right">
                                <button onClick={this.apagaDados} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Sim</button>
                            </div>

                            <div className="col text-left">
                                <button onClick={this.ModalCancelamento} className="btn btn-outline-danger btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Não</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de erro
                    isOpen={this.state.erro}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center modalErro">
                        <label>{this.state.mensagemModal} </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p> {this.state.tipoErro} </p>}
                        <div className="row">
                            <div className="col">
                                <button onClick={this.ModalErro} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de erro refresh
                    isOpen={this.state.refresh}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center modalErro">
                        <label> Ops! Temos problemas: </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p> {this.state.tipoErro} </p>}
                        <div className="row">
                            <div className="col">
                                <Link to="/"><button onClick={this.ModalRefresh} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button></Link>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de Loading
                    isOpen={this.state.loading}
                    className="estilizacaoModais col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label> Carregando dados... </label><br />
                        <Spinner color="primary" size="lg" />
                    </div>
                </Modal>
                <div className="col-12 col-sm-11 col-md-9 col-lg-10 col-xl-8">
                    <div>
                        <div className="h4 text-center">
                            Relatórios
                        </div>

                        <form onSubmit={this.OnClickEnvia}>
                            <div className="form-row">
                                <div className="form-group col-12 col-sm-6">
                                    <label htmlFor="dataInicio">Data Inicio:</label>
                                    <input
                                        className={this.state.dataInicio_erro ? 'form-control is-invalid' : 'form-control'}
                                        type="date"
                                        id="dataInicio"
                                        name="dataInicio"
                                        value={this.state.dataInicio}
                                        onChange={this.atribuidataInicio}
                                        required="required"
                                        autoComplete="off"
                                        min="2000-01-01"
                                    />
                                </div>

                                <div className="form-group col-12 col-sm-6">
                                    <label htmlFor="dataFim">Data Final:</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="dataFim"
                                        value={this.state.dataFim}
                                        onChange={this.atribuidataFim}
                                        required="required"
                                        autoComplete="off"
                                        min="2000-01-01"
                                    />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-12">
                                    <Select
                                        value={this.state.tipoRelatorio}
                                        onChange={this.atribuiTipoRelatorio}
                                        options={options}
                                    />
                                </div>
                            </div>
                            
                            {/* Se o relatorio selecionado for Listagem ou Espelho, 
                            Vai aparecer o select com os colaboradores ativos,
                            Senão vai aparecer o select com os colaboradores desligados - Alterado Jorge */}
                            {selectUsuarios ? selectUsuarios : selectUsuariosDesligados}

                            <div className="form-row">
                                <div className="form-group col text-right">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-sm col-7 col-sm-6 col-md-5 col-lg-4 col-xl-4">
                                        Baixar
                                    </button>
                                </div>
                                <div className="form-group col text-left">
                                    <button
                                        type="button"
                                        onClick={this.ModalCancelamento}
                                        className="btn btn-danger btn-sm col-7 col-sm-6 col-md-5 col-lg-4 col-xl-4">
                                        Cancelar
                                        </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {redirecionamento}
            </div >
        );
    }
}

const mapStateToProp = (state) => ({
    empresaSelecionada: state.dadosEmpresa.empresaSelecionada
});

export default connect(mapStateToProp)(FormularioRelatorio);