import React from "react";
import { Switch, Route } from 'react-router-dom';

import TabelaEmpresa from './tabela_empresa';
import NavbarRotaEmpresa from './navbar_rotaEmpresa';

const RotasEmpresa = () => (
    <Switch>
        <Route path='/Home/Empresa/Tabela' component={TabelaEmpresa} />
        <Route path='/Home/Empresa/NovaEmpresa' component={NavbarRotaEmpresa} />
    </Switch>
);

export default RotasEmpresa;