import React, { Component } from "react";
import { Switch, Route } from 'react-router-dom';

import Login from './components/login/login';
import Empresa from './components/empresa/mainEmpresa'
import Colaborador from './components/colaborador/mainColaborador'
import User from './components/userSistema/mainUser';
import Home from "./components/Home/homePage";
import Relatorio from './components/relatorios/mainRelatorio';
import Permissoes from './components/permissoes/mainPermissoes'

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistor, store } from './configureStore';

class Rotas extends Component {
    render() {
        return (
            <div>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <Switch>
                            <Route exact path="/" component={Login} />
                            <Route path="/Home" component={Home} />
                            <Route path="/Home/Empresa/" component={Empresa} />
                            <Route path="/Home/Colaborador/" component={Colaborador} />
                            <Route path="/Home/User/" component={User} />
                            <Route exact path="/Home/Relatorio" component={Relatorio} />
                            <Route exact path="/Home/Permissoes" component={Permissoes} />
                        </Switch>
                    </PersistGate>
                </Provider>
            </div>
        );
    }
}

export default Rotas;