const initialState = {
    empresaSelecionada: '',
    options: '',
    role: '',
    conectado: false,
    id: '',
    username: ''
}

const adicionaIDEmpresa = (state = initialState, action) => {
    switch (action.type) {
        case 'changeIDEmpresa':
            return { ...state, empresaSelecionada: action.payload.empresaSelecionada }

        case 'changeEmpresas':
            return { ...state, options: action.payload.options }

        case 'changeRole':
            return { ...state, role: action.payload.role }

        case 'changeReset':
            return { ...state, options: action.payload.options, empresaSelecionada: action.payload.empresaSelecionada }

        case 'changeConectado':
            return { ...state, conectado: action.payload.conectado }

        case 'changeLogout':
            return {
                ...state, conectado: action.payload.conectado,
                username: action.payload.username,
                id: action.payload.id
            }

            case 'changeID':
                return { ...state, id: action.payload.id }

                
        case 'changeUsername':
            return { ...state, username: action.payload.username }

        default:
            return state;

    }
};

export default adicionaIDEmpresa;