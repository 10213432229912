import React from 'react';

import { BrowserRouter } from 'react-router-dom';
import { Router } from 'react-router';
import { createBrowserHistory } from "history";

import Rotas from './rotas';
const history = createBrowserHistory();

function App() {
  return (
    <div>
      <BrowserRouter>
        <Router history={history}>
          <Rotas />
        </Router>
      </BrowserRouter>
    </div>
  );
}

export default App;
