import React, { Component } from "react";
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import TabelaEmpresa from '../empresa/tabela_empresa';
import Formulario from "../empresa/form_empresa";

import TabelaColaborador from '../colaborador/tabela_colaborador'
import FormularioColaborador from '../colaborador/form_colaborador';

import TabelaUser from '../userSistema/tabela_User';
import FormularioUser from '../userSistema/form_user';

import TabelaMarcacoes from '../marcacoes/tabelaMarcacoes';

import TabelaPermissao from '../permissoes/tabela_Permissoes';
import FormularioPermissao from '../permissoes/form_permissoes';

import Relatorio from '../relatorios/mainRelatorio';
import MainAFD from '../AFDFiscal/mainAFD';
import MainUpload from '../upload/mainUpload';

const ROLE_Admin = "Admin";
const ROLE_RH = "RH";
const ROLE_User = "User";

class RotasSistema extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }
        this.verificaRole = this.verificaRole.bind(this);
    }
    verificaRole() {
        switch (this.props.role) {
            case ROLE_Admin:
                return true;

            case ROLE_RH:
                return true;

            case ROLE_User:
                return false;

            default:
                return false;
        }
    }
    render() {
        return (
            <div>
                <Switch>
                    <Route path='/Home/Empresa/Tabela' component={TabelaEmpresa} />
                    <Route path='/Home/Empresa/NovaEmpresa' component={Formulario} />

                    <Route path='/Home/Colaborador/Tabela' component={TabelaColaborador} />
                    <Route path='/Home/Colaborador/NovoColaborador' component={FormularioColaborador} />

                    <Route path='/Home/User/Tabela' component={TabelaUser} />
                    <Route path='/Home/User/NovoUser' component={FormularioUser} />

                    <Route path='/Home/Marcacoes' component = {TabelaMarcacoes}/>

                    <Route path='/Home/Permissoes/Tabela' component={TabelaPermissao} />
                    <Route path='/Home/Permissoes/NovaPermissao' component={FormularioPermissao} />

                    <Route path='/Home/Relatorio/' component={Relatorio} />
                    <Route path='/Home/AFDFiscal/' component={MainAFD} />
                    <Route path='/Home/UploadArquivo/' component={MainUpload} />
                    
                    <Route path='*'>
                        <Redirect to='/' />
                    </Route>
                </Switch>
            </div>
        );
    }
}

const mapStateToProp = (state) => ({
    role: state.dadosEmpresa.role
});

export default connect(mapStateToProp)(RotasSistema);