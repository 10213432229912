import React, { Component } from 'react';

export default class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    render() {
        return (
            <footer className="page-footer footer-position tamanhofooter color-footer footercontent footersite bg-primary">
                <div className="text-center">
                    <p>
                        suporte@horasol.com | (16) 3322-5933<br />
                        <a className="text-white" href="http://www.horasol.com" target="_blank" rel="noopener noreferrer"> www.horasol.com </a><br />
                        <span className="tamanhoFonte">CNPJ: 50.399.542/0001-57</span>
                    </p>
                </div>
                <div className="text-center paddingBottom">
                    <span className="small"> © 2020 Copyright - Todos os direitos reservados </span> | <a className="text-white small" href="https://hssistema.com/Privacidade" target="_blank" rel="noopener noreferrer"> Termos de Uso e Política de Privacidade</a> 
                </div>
            </footer>
        );
    }

}