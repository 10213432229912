import React from "react";
import { Switch, Route } from 'react-router-dom';

import TabelaColaborador from './tabela_colaborador';
import FormularioColaborador from './form_colaborador';

const RotasColaborador = () => (
    <Switch>
        <Route path='/Home/Colaborador/Tabela' component={TabelaColaborador} />
        <Route path='/Home/Colaborador/NovoColaborador' component={FormularioColaborador} />
    </Switch>
);

export default RotasColaborador;